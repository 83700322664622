import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { getCookie } from '../../helpers/Auth'
import Dialogue from './Dialogue'

export default function CreateLesson() {
    const [courses, setCourses] = useState([])
    const [lessons, setLessons] = useState([])
    const [lesson, setLesson] = useState({
        id: null,
        topic: '',
        imgSrc: '',
        description: '',
        dialogues: []
    })
   
    const [token, setToken] = useState(null)
    const [selectedCourse, setSelectedCourse] = useState(null)

    const [info, setInfo] = useState(null)
    const [error, setError] = useState(null)

    const loadData = async () => {
        let response = await axios.get(`${process.env.REACT_APP_API_URL}/courses/list`)
        setCourses(response.data.courses)
    }

    useEffect(() => {
        loadData()
        

    }, [])


    
    // Fetch the selected single course 
    const handleSelect = (e) => {
        if(e.target.value !== 'NA'){
            setSelectedCourse(courses[e.target.value])
            setLesson({ ...lesson, id: courses[e.target.value].lessons.length + 1 })
            console.log(courses[e.target.value])
        }
    }
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + getCookie('token')
        }
    }
    const changeHandler = (e) => {
        lesson[e.target.name] = e.target.value
        if (e.target.name == 'imgSrc') {

            lesson[e.target.name] = e.target.files[0]
        }
        setLesson({ ...lesson })

    }

    const submitHandler = (e) => {
        e.preventDefault()
        const formData = new FormData()
        formData.append("id",lesson.id)
        formData.append("topic", lesson.topic)
        formData.append("description",lesson.description)
        formData.append("courseCode",selectedCourse.code)
        formData.append("imgSrc",lesson.imgSrc)
        
        axios.put(`${process.env.REACT_APP_API_URL}/courses/${selectedCourse._id}/lesson/create`,
            formData,
            config
        )
        .then(res => {
            console.log(res.data)
            
            setLesson({
                id: lesson.id+1,
                topic: '',
                imgSrc: '',
                description: '',
                dialogues: []
            })
            //setSelectedCourse(null)
            loadData()
            setInfo(res.data)
            setError('')
        })
        .catch(err => {
            setInfo('')
            
            if(err.response){
                console.log(err.response.data)
                setError(err.response.data)
            }
        })
        

    }
    // Value coming from dialogue component
    const setDialogueHandler = (dialogue) => {
        
        let tempLesson = lesson
        tempLesson.dialogues.push(dialogue)
        setLesson({...lesson})
        


    }

    // const handleLessonSubmit = (e) => {
    //     e.preventDefault()
    //     let tempCourse = selectedCourse
    //     tempCourse.lessons.push(lesson)
    //     setSelectedCourse(tempCourse)
    //     console.log(selectedCourse)

    // }
    const { id, topic, description, imgSrc } = lesson
    return (
        <div className="container-fluid p-2">
            <h3>Create Lessons Interface For A Selected Course</h3>
            <hr/>
               { (info) && <div className="bg-success p-2 text-white">{info}<br/></div>}
               { (error) && <div className="bg-danger p-2 text-white">{error}<br/></div>}

            <form onSubmit={e => submitHandler(e)}>
                <div className="form-group">
                    <label htmlFor="exampleFormControlSelect1">Select The Course</label>
                    <select className="form-control" id="code" name="code" onChange={e => handleSelect(e)}>
                        <option value="NA">Please Select...</option>
                        {courses.map((course, index) => {
                            return <option value={index} key={index}>PBL-{course.code} {course.title}</option>
                        })}
                    </select>
                </div>
                <div>
                    <h4 className="text-primary"><label htmlFor="exampleFormControlSelect1">Add  <span className="text-primary">Lesson - {lesson.id}</span>  for the selected course</label></h4>
                    <br />
                </div>
                <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Topic of The Lesson</label>
                    <input
                        type="text"
                        name="topic"
                        className="form-control"
                        id="topic"
                        aria-describedby="emailHelp"
                        placeholder="Enter Name Of the Course"
                        onChange={e => changeHandler(e)}
                        value={topic}
                        required

                    />
                    <small id="emailHelp" className="form-text text-muted">Topic of the Lesson to be displayed on the website</small>
                </div>
                <div className="form-group">
                    <label htmlFor="exampleInputPassword1">Description Of the Lesson</label>
                    <textarea
                        className="form-control"
                        name="description"
                        id="description"
                        placeholder="Description"
                        rows="5"
                        cols="10"
                        onChange={e => changeHandler(e)}
                        value={description}
                        required
                    >

                    </textarea>
                </div>
                <div className="form-group">
                    <label htmlFor="exampleFormControlFile1">Upload Course Feature Image</label>
                    <input
                        type="file"
                        className="form-control-file"
                        name="imgSrc"
                        id="imgSrc"
                        onChange={e => changeHandler(e)}
                        required
                    />
                </div>
                <div className="form-group">
                    <button type="submit" className="btn btn-primary">Submit</button>  | <a href="/admin/lesson/view" className="btn btn-small btn-primary">Go Back to Lessons Interface</a>
                </div>
                


            </form>
            {/* <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal">
                Add Dialogues
            </button>
            <div>
                { lesson.dialogues.map(dialogue => {
                    return <div className="row">
                                    <div className="col-md-2">{dialogue.english}</div>
                                    <div className="col-md-2">{dialogue.punjabi}</div>
                                    <div className="col-md-2">{dialogue.pbInEng}</div>
                                    <div className="col-md-2">{dialogue.audio}</div>
                        </div>
                })}
                </div>
            
            <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title text-primary" id="exampleModalLabel">Create Dialogue-{lesson.dialogues.length+1}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <Dialogue dialogueNo={lesson.dialogues.length} setDialogueHandler={setDialogueHandler}/>
                        
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary">Save changes</button>
                        </div>
                    </div>
                </div>
            </div>
            <br/>
            <br/>
            <form onSubmit={e => handleLessonSubmit(e)}>
                <div>
                    <div className="form-group">
                        <button type="submit" className="btn btn-primary">Submit The Lesson</button>
                    </div>
                </div>
            </form> */}
        </div>
    )
}
