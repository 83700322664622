import React from 'react'
import Footer from '../footers/Footer'
import HeaderMain from '../headers/headerMain/HeaderMain'
import Menu from '../headers/headerMain/Menu'
import HeroDonate from '../heros/HeroDonate'



import Donate from '../pages/donation/Donate'

export default function DonateView() {
    return (
        <div  className="super_container">
            <HeaderMain/>
            <Menu/>
            <HeroDonate/>
            <Donate/>
            
            
            <br/>
            <br/>
           
            <Footer/>

          
        </div>
    )
}
