import React from 'react'

export default function Footer() {
    return (
        <div>
            <footer className="sticky-footer bg-white">
                <div className="container my-auto">
                    <div className="copyright text-center my-auto">
                        <span>Copyright &copy; I Know Punjabi 2021 </span>
                        
                    </div>
                   

                </div>
            </footer>
        </div>
    )
}

