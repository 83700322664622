import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Page404 from './components/Page404';
import AdminLayout from './layout/AdminLayout';
import AuthLayout from './layout/AuthLayout';
import HomePage from './components/views/HomePage';
import CourseView from './components/views/CourseView';
import LessonView from './components/views/LessonView';
import Landing from './components/views/users/Landing';
import CatalogView from './components/views/CatalogView';
import ContactView from './components/views/ContactView';
import AboutView from './components/views/AboutView';
import DonateView from './components/views/DonateView';


ReactDOM.render(
  <BrowserRouter>
    <Switch>
      
      <Route path="/auth"  component={AuthLayout} />

      {/* Path for admin routes */}
      <Route path="/admin"  component={AdminLayout} />

      {/* Normal Routes */}
      <Route path="/" exact component={HomePage} />
      <Route path="/landing" exact component={Landing} />
      
      <Route path="/course/:id" exact  render={props => <CourseView  {...props}/>}/>

      <Route path="/catalog" exact component={CatalogView} />
      {/* <Route path="/lesson/:id" exact component={LessonView} /> */}
      <Route path="/course/:cid/lesson/:id" exact render={props => <LessonView  {...props}/>} />
      <Route path="/about-us" exact component={AboutView} />


      <Route path="/contact-us" exact component={ContactView} />
      <Route path="/donate" exact component={DonateView} />
      <Route path="*" exact={true} component={Page404}/>


    </Switch>
  </BrowserRouter>,
  document.getElementById('root')
);

