import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import JoditEditor from "jodit-react";

import { getCookie } from '../../helpers/Auth'

export default function CreateQuestion() {
    const [courses, setCourses] = useState([])
    const config3 = {
		readonly: false // all options from https://xdsoft.net/jodit/doc/
    }
    const editor = useRef(null)
	const [content, setContent] = useState('')
    const [practices, setPractices] = useState([])
    

    const [practice, setPractice] = useState({
        id:null,
        question:'',
        options:[],
        correct:'',
        type:'mcq'
    }) 

    const [option, setOption] = useState(null)
   
    const [token, setToken] = useState(null)
    const [selectedCourse, setSelectedCourse] = useState(null)

    const [info, setInfo] = useState(null)
    const [error, setError] = useState(null)

    const [lessonId, setLessonId] = useState(null)
    const loadData = async () => {
        let response = await axios.get(`${process.env.REACT_APP_API_URL}/courses/list`)
        setCourses(response.data.courses)
    }

    useEffect(() => {
        loadData()
        

    }, [])


    
    // Fetch the selected single course 
    const handleSelect = (e) => {
        if(e.target.value !== 'NA'){
            setSelectedCourse(courses[e.target.value])
            if(courses[e.target.value].lessons.length == 0){
              
                setPractice({})
            }
            //setLesson({ ...lesson, id: courses[e.target.value].lessons.length + 1 })
           // console.log(courses[e.target.value])
        }
    }

    const handleLessonSelect = (e) => {
        if(e.target.value !== 'NA'){
            let tempLesson = selectedCourse.lessons[e.target.value]
           // console.log(tempLesson)
            setLessonId(e.target.value)
            if(tempLesson.practices){
                setPractice({...practice, id:tempLesson.practices.length + 1})
               
                //console.log("Question Exist")

            }else{
                setPractice({...practice, id:1})

                //console.log("Question Missing")
            }
            // setLesson({ ...lesson, id: courses[e.target.value].lessons.length + 1 })
            // console.log(courses[e.target.value])
        }
    }
    const config = {
        headers: {
            
            'Authorization': 'Bearer ' + getCookie('token')
        }
    }
    const changeHandler = (e) => {
        if(e.target.name === 'option'){
            setOption(e.target.value)
        }

    }

    const submitHandler = (e) => {
        e.preventDefault()
       // console.log(practice)
        // const formData = new FormData()
        // formData.append("id",lesson.id)
        // formData.append("topic", lesson.topic)
        // formData.append("description",lesson.description)
        // formData.append("courseCode",selectedCourse.code)
        // formData.append("imgSrc",lesson.imgSrc)
        
        axios.put(`${process.env.REACT_APP_API_URL}/courses/${selectedCourse._id}/lessons/${lessonId}/practice`,
            practice,
            config
        )
        .then(res => {
            console.log(res.data)
            let tempLesson = res.data.course.lessons[lessonId]
           // console.log(tempLesson)
            //setLessonId(e.target.value)
            if(tempLesson.practices){
                setPractice({...practice, id:tempLesson.practices.length + 1})
               
                //console.log("Question Exist")

            }
            
            //setSelectedCourse(null)
            //setPractice(null)
	    
            setInfo(res.data.message)
            setError('')
        })
        .catch(err => {
            setInfo('')
            
            if(err.response){
                console.log(err.response.data)
                setError(err.response.data)
            }
        })
        

    }
    // Value coming from dialogue component
   
    const addOptions = () => {
        if(option){
            practice.options.push(option)
        }
        setPractice({...practice})
        
    }

    const removeOption = (e, idx) => {
        if(practice){
            practice.options.splice(idx,1)
        }
        setPractice({...practice})

    }

    const handleCorrectSelect = (e) => {

        practice.correct = e.target.value
        setPractice({...practice})
        //console.log(practice)

    }

    // const handleLessonSubmit = (e) => {
    //     e.preventDefault()
    //     let tempCourse = selectedCourse
    //     tempCourse.lessons.push(lesson)
    //     setSelectedCourse(tempCourse)
    //     console.log(selectedCourse)

    // }
    return (
        <div className="container-fluid p-2">
            <h3>Create Question Interface For A Selected Lesson</h3>
            <hr/>
               { (info) && <div className="bg-success p-2 text-white">{info}<br/></div>}
               { (error) && <div className="bg-danger p-2 text-white">{error}<br/></div>}

            <form onSubmit={e => submitHandler(e)}>
                <div className="form-group">
                    <label htmlFor="exampleFormControlSelect1">Select The Course</label>
                    <select className="form-control" id="code" name="code" onChange={e => handleSelect(e)}>
                        <option value="NA">Please Select...</option>
                        {courses.map((course, index) => {
                            return <option value={index} key={index}>PBL-{course.code} {course.title}</option>
                        })}
                    </select>
                </div>
                    { selectedCourse && <div className="form-group">
                        <label htmlFor="exampleFormControlSelect1">Select The Course</label>
                        <select className="form-control" id="lessonId" name="lessonId" onChange={e => handleLessonSelect(e)}>
                            <option value="NA">Please Select...</option>
                            {selectedCourse.lessons.map((lesson, index) => {
                                return <option value={index} key={index}> {lesson.topic}</option>
                            })}
                        </select>
                    </div>
                    }

               { practice && practice.id && <div>
                    <h4 className="text-primary"><label htmlFor="exampleFormControlSelect1">Add MCQ Based <span className="text-primary">Practice Question - {practice.id}</span>  for the Selected Lesson</label></h4>
                    <br />
                </div>
                }
               {practice && practice.id && <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Question</label>
                    <JoditEditor
                            ref={editor}
                            value={content}
                            config={config3}
                            id="question"
                            name="question"
                            tabIndex={1} // tabIndex of textarea
                            onBlur={newContent => setPractice({...practice, question:newContent})} // preferred to use only this option to update the content for performance reasons
                           // onChange = {e => changeHandler(e)}
                     />
                    <small id="emailHelp" className="form-text text-muted"></small>
                </div> }
               { practice && practice.id &&  <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Enter Option's Here</label>
                        <input 
                        type="text"
                        name="option"
                        className="form-control"
                        id="option"
                        aria-describedby="emailHelp"
                        placeholder="Enter the option as desired"
                        onChange = {e => changeHandler(e)}
                       
                        required
                        
                        />
                        <small id="emailHelp" className="form-text text-muted">Specify the Option for the selected question</small>
                </div>
                }

                { practice && practice.id &&   <button type="button" className="btn btn-success float-right" onClick={addOptions}>Add Options</button> }


                <br/>
                
                <h5>Options Given By You:</h5>
                <ol className="list-group" type='A'>
                {
                   practice && practice.options && practice.options.map((option, index) => {
                        return  <li className="list-group-item" key={index}>{option}
                                <button onClick={e => removeOption(e, index)} className="btn-sm border btn-danger float-right">X</button>
                         </li>
                    })
                }
                </ol>
                <br/>
                {/* <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Enter Option B </label>
                        <input 
                        type="text"
                        name="optionB"
                        className="form-control"
                        id="optionB"
                        aria-describedby="emailHelp"
                        placeholder="Option B"
                        onChange = {e => changeHandler(e)}
                       
                        required
                        
                        />
                        <small id="emailHelp" className="form-text text-muted">Specify the Option B for the selected question</small>
                </div>
                <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Enter Option C </label>
                        <input 
                        type="text"
                        name="optionC"
                        className="form-control"
                        id="optionC"
                        aria-describedby="emailHelp"
                        placeholder="Option C"
                        onChange = {e => changeHandler(e)}
                       
                        required
                        
                        />
                        <small id="emailHelp" className="form-text text-muted">Specify the Option C for the selected question</small>
                </div>
                <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Enter Option D </label>
                        <input 
                        type="text"
                        name="optionD"
                        className="form-control"
                        id="optionD"
                        aria-describedby="emailHelp"
                        placeholder="Option D"
                        onChange = {e => changeHandler(e)}
                       
                        required
                        
                        />
                        <small id="emailHelp" className="form-text text-muted">Specify the Option D for the selected question</small>
                </div> */}
                <br/>
               { practice && practice.options && practice.options.length > 0 && <div className="form-group">
                        <label htmlFor="exampleInputEmail1" className="text-danger">Correct Option </label>
                        <select className="form-control" id="correct" name="correct" onChange={e => handleCorrectSelect(e)}>
                        <option value="NA">Choose the correct Answer...</option>
                        {   practice.options && practice.options.map((option, index) => {
                            
                                return <option value={index}>{option}</option>
                            })
                        }
                       
                    </select>
                        <small id="emailHelp" className="form-text text-muted">Specify the Correct Option A or B or C or D for the selected question</small>
                    </div>
                }

                {practice && practice.correct &&
                    <div className="form-group">
                        <button type="submit" className="btn btn-primary">Submit</button>  | <a href="/admin/lesson/view" className="btn btn-small btn-primary">Go Back to Lessons Interface</a>
                    </div>
                }
                
                    

            </form>
            {/* <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal">
                Add Dialogues
            </button>
            <div>
                { lesson.dialogues.map(dialogue => {
                    return <div className="row">
                                    <div className="col-md-2">{dialogue.english}</div>
                                    <div className="col-md-2">{dialogue.punjabi}</div>
                                    <div className="col-md-2">{dialogue.pbInEng}</div>
                                    <div className="col-md-2">{dialogue.audio}</div>
                        </div>
                })}
                </div>
            
            <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title text-primary" id="exampleModalLabel">Create Dialogue-{lesson.dialogues.length+1}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <Dialogue dialogueNo={lesson.dialogues.length} setDialogueHandler={setDialogueHandler}/>
                        
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary">Save changes</button>
                        </div>
                    </div>
                </div>
            </div>
            <br/>
            <br/>
            <form onSubmit={e => handleLessonSubmit(e)}>
                <div>
                    <div className="form-group">
                        <button type="submit" className="btn btn-primary">Submit The Lesson</button>
                    </div>
                </div>
            </form> */}
        </div>
    )
}
