import React, {useState, useEffect} from 'react'
import axios from 'axios'
export default function Register() {
    const [formData, setFormData] = useState({
        fname:'',
        lname:'',
        email:'',
        password:''
        
    })
    const [info, setInfo] = useState(null)
    const [error, setError] = useState(null)

    const changeHandler = (e) => {
        formData[e.target.name] = e.target.value
        setFormData(formData)
       
        
    }

    const submitHandler = (e) => {
        e.preventDefault()
        axios.post(`${process.env.REACT_APP_API_URL}/auth/register`, formData)
        .then(res => {
            //console.log(res.data)
            setInfo(res.data.message)
            setTimeout(() => {
                setInfo('')
            },10000)

        })
        .catch(err => {
            //console.log(err.response.data)
            setError(err.response.data)
            setTimeout(() => {
                setError('')
            },5000)
        })
    }
    return (
        <div>
            <div className="container">

                <div className="card o-hidden border-0 shadow-lg my-5">
                    <div className="card-body p-0">
                        <div className="row">
                            <div className="col-lg-5 d-none d-lg-block bg-register-image"></div>
                            <div className="col-lg-7">
                                <div className="p-5">
                                    <div className="text-center">
                                    
                                        <span><img src={`${process.env.PUBLIC_URL}/assets/img/logo1.png`} /></span>
                                        <br/>
                                        <h1 className="h4 text-gray-900 mb-4">Register With Us!</h1>
                                        
                                    </div>
                                    {(info) && <div className="p-2 text-success">{info}</div>}
                                    {(error) && <div className="p-2  text-danger">{error}</div>}
                                    <br/>
                                    <form className="user" >
                                        <div className="form-group row">
                                            <div className="col-sm-6 mb-3 mb-sm-0">
                                                <input
                                                 type="text"
                                                 className="form-control form-control-user"
                                                 id="fname"
                                                 name="fname"
                                                 placeholder="First Name" 
                                                 onChange = {e => changeHandler(e)}
                                                 required
                                                 />
                                            </div>
                                            <div className="col-sm-6">
                                            <input
                                                 type="text"
                                                 className="form-control form-control-user"
                                                 id="lname"
                                                 name="lname"
                                                 placeholder="Last Name" 
                                                 onChange = {e => changeHandler(e)}
                                                 required
                                                 />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <input
                                                 type="email"
                                                 className="form-control form-control-user"
                                                 id="email"
                                                 name="email"
                                                 placeholder="Email Address" 
                                                 onChange = {e => changeHandler(e)}
                                                 required
                                                 />
                                            
                                        </div>
                                        <div className="form-group">
                                                <input
                                                 type="password"
                                                 className="form-control form-control-user"
                                                 id="password"
                                                 name="password"
                                                 placeholder="Password" 
                                                 onChange = {e => changeHandler(e)}
                                                 required
                                                 />
                                            </div>
                                        {/* <div className="form-group row">
                                            
                                            
                                            <div className="col-sm-6">
                                            <input
                                                 type="password"
                                                 className="form-control form-control-user"
                                                 id="repeatPassword"
                                                 name="repeatPassword"
                                                 placeholder="" 
                                                 onChange = {e => changeHandler(e)}
                                                 required
                                                 />
                                            
                                            </div>
                                        </div> */}
                                        <a href="login.html" className="btn btn-primary btn-user btn-block" onClick = {e => submitHandler(e)}>
                                            Register Account
                                        </a>
                                        {/* <hr/>
                        <a href="index.html" className="btn btn-google btn-user btn-block">
                            <i className="fab fa-google fa-fw"></i> Register with Google
                        </a>
                        <a href="index.html" className="btn btn-facebook btn-user btn-block">
                            <i className="fab fa-facebook-f fa-fw"></i> Register with Facebook
                        </a> */}
                                    </form>
                                    <hr />
                                    <div className="text-center">
                                        <a className="small" href="forgot-password.html">Forgot Password?</a>
                                    </div>
                                    <div className="text-center">
                                        <a className="small" href="login.html">Already have an account? Login!</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
