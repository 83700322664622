import React, { useState, useEffect, Component } from 'react'
import axios  from 'axios'
import { getCookie } from '../../helpers/Auth'
export default function ViewLessons() {
    const [courses, setCourses] = useState([])
    const [lessons, setLessons] = useState([])
    const [lesson, setLesson] = useState({
        id: null,
        topic: '',
        imgSrc: '',
        description: '',
        dialogues: []
    })
   
    const [token, setToken] = useState(null)
    const [selectedCourse, setSelectedCourse] = useState(null)

    const [info, setInfo] = useState(null)
    const [error, setError] = useState(null)

    const loadData = async () => {
        let response = await axios.get(`${process.env.REACT_APP_API_URL}/courses/list`)
        setCourses(response.data.courses)
    }

    useEffect(() => {
        loadData()
        

    }, [])
    
    useEffect(() => {
          // Do something on lessons change

    },[lessons])

    const handleSelect = async (e) => {
       
        if(e.target.value !== 'NA'){
           // console.log(e.target.value)
            setSelectedCourse(courses[e.target.value])
            await axios.get(`${process.env.REACT_APP_API_URL}/courses/${courses[e.target.value]._id}/lessons`)
            .then(res => {
                const course = res.data.course
                //console.log(course.title)
                setLessons(res.data.course.lessons)

            })
            .catch(err => {
                if(err.response){
                    console.log(err.response.data)
                }
                
            })        

        }else{
            setSelectedCourse(null)
            setLessons([])
        }
        
       // console.log(courses[e.target.value])
    }

    const submitHandler = (e) => {
        e.preventDefault()
    }

    const handleDelete = (index) => {
        let x =  window.confirm('Do you really want to Delete this record?')
        if(x){

            axios.delete(`${process.env.REACT_APP_API_URL}/courses/${selectedCourse._id}/lessons/${index}`)
            .then(res => {
                setInfo(res.data.message)
                setError('')
                setLessons(res.data.course.lessons)
            })
            .catch(err => {
                setInfo('')
                
                if(err.response){
                    setError(err.response.data)
                    console.log(err.response.data)
                }
                
            })
        }
    }
    return (
        <div className="container-fluid p-5">
            <h3>View Lessons    </h3>
            <hr/>
            { (info) && <div className="p-2 text-success">{info}<br/></div>}
               { (error) && <div className="p-2 text-danger">{error}<br/></div>}
             <form onSubmit={e => submitHandler(e)}>
                <div className="form-group">
                    <label htmlFor="exampleFormControlSelect1">Select The Course To View Lesson Details</label>
                    <select className="form-control" id="code" name="code" onChange={e => handleSelect(e)}>
                        <option value='NA'>Please Select...</option>
                        {courses.map((course, index) => {
                            return <option value={index} key={index}>PBL-{course.code} {course.title}</option>
                        })}
                    </select>
                </div>
                <hr/>


            </form>
            { (lessons && selectedCourse) && <table class="table stripped">
                <thead className="thead-dark">
                    <tr>
                    <th scope="col">#</th>
                    <th scope="col">Topic</th>
                   {/* <th scope="col">Description</th> */}
                    <th scope="col">Feature Image</th>
                    <th scope="col">View Dialogues</th>
                    <th scope="col">Action</th>
                    
                    </tr>
                </thead>
                <tbody>
                    {
                        lessons.map((lesson, index) => {
                            return <tr key={index}>
                                <td>Lesson-{lesson.id}</td>
                                <td>{lesson.topic}</td>
                               {/* <td>{lesson.description}</td>*/}
                                <td><a href=""  data-toggle="modal" data-target="#exampleModal"><img src={`${process.env.PUBLIC_URL}/uploads/courses/course-${selectedCourse.code}/lesson-${lesson.id}/${lesson.imgSrc}`} width="50px" height="50px" /></a></td>
                                <td><a href={`/admin/course/${selectedCourse._id}/lesson/${index}/dialogue/add`} className='btn text-success btn-link'>Manage Dialogue</a></td>
                                <td><a href={`/admin/course/${selectedCourse._id}/lesson/${index}`} className="btn text-primary btn-link">Edit</a> | <button onClick={e => handleDelete(index)} className="btn text-danger btn-link">Delete</button></td>
                            </tr>
                        })
                    }

                </tbody>
            </table> }

            <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title text-primary" id="exampleModalLabel">Change Image</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                           
                        
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary">Save changes</button>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    )
}
