import React, { useState, useEffect } from 'react'
import axios from 'axios'

export default function AboutPage() {
    const [content, setContent] = useState('')
    const [content2, setContent2] = useState('')

    const loadData = async () => {
        await axios.get(`${process.env.REACT_APP_API_URL}/pages/tegh`)
            .then(res => {

                //console.log("On load: "+res.data.content)
                setContent(res.data.content)
                //console.log(content)
            })
            .catch(err => console.log(err.message))

        await axios.get(`${process.env.REACT_APP_API_URL}/pages/platform`)
            .then(res => {

                //console.log("On load: "+res.data.content)
                setContent2(res.data.content)
                //console.log(content)
            })
            .catch(err => console.log(err.message))
    }

    useEffect(() => {
        loadData()

    }, [])
    return (
        <div>
            <div className="about">
                <div className="container">
                    <div className="row about_row row-lg-eq-height">
                        <div className="col-lg-6">
                            <div className="about_content">
                            <div className="about_title">Tegh Education Society</div>
                                <div className="about_text">
                                    <div dangerouslySetInnerHTML={{ __html: content }} />  
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about_image"><img src={`${process.env.PUBLIC_URL}/assets/mytheme/images/about_11.jpg`} alt="Not Displayed" /></div>
                        </div>
                    </div>
                    <div className="row about_row row-lg-eq-height">
                        <div className="col-lg-6 order-lg-1 order-2">
                            <div className="about_image"><img src={`${process.env.PUBLIC_URL}/assets/mytheme/images/about_11.jpg`} alt="" /></div>
                        </div>
                        <div className="col-lg-6 order-lg-2 order-1">
                            <div className="about_content">
                                <div dangerouslySetInnerHTML={{ __html: content2 }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
