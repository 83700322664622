import React,{useState, useEffect} from 'react'
import axios from 'axios'
export default function BottomSection() {
    const [content, setContent] = useState('')
    
    const loadData = async () => {
        await axios.get(`${process.env.REACT_APP_API_URL}/pages/join`)
          .then(res => {
  
              //console.log("On load: "+res.data.content)
              setContent(res.data.content)
              //console.log(content)
          })
          .catch(err => console.log(err.message))
      }
  
      useEffect(() => {
         loadData()
        
      },[])
    return (
        <div>
            <div className="video">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="video_container_outer">
                                <div className="video_container">

                                    <video id="vid1" className="video-js vjs-default-skin" controls data-setup='{ "poster": "images/video.jpg", "techOrder": ["youtube"], "sources": [{ "type": "video/youtube", "src": "https://youtu.be/5_MRXyYjHDk"}], "youtube": { "iv_load_policy": 1 } }'>
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="join">
                <div className="container">
                    <div className="row">
                        <div dangerouslySetInnerHTML={{__html: content}} />
                    </div>
                </div>
                <div className="button join_button"><a href="#">Donate Us<div className="button_arrow"><i className="fa fa-angle-right" aria-hidden="true"></i></div></a></div>
            </div>
            <br/>
            <br/>   
        </div>
    )
}
