import React, { Component } from 'react'
import axios  from 'axios'
import { getCookie } from '../helpers/Auth'
export class CourseList extends Component {
    
    constructor() {

        super()
        this.state = {courses: [], token: null }
        
        this.deleteHandler = this.deleteHandler.bind(this)
       // this.updateHandler = this.updateHandler.bind(this)
    }   

   
   async loadData(){
        let response = await axios.get(`${process.env.REACT_APP_API_URL}/courses/list`)
        this.setState({ courses : response.data.courses })
    }
    componentDidMount = async () => {

        this.loadData()
        
        let tokenCookie = getCookie('token')
        if(tokenCookie){
            this.setState({
                token : tokenCookie
            })
        }
      

    }

   

     deleteHandler = (e, id) => {
        e.preventDefault()
        let x =  window.confirm('Do you really want to Delete this record?')
        if(x){
            axios.delete(`${process.env.REACT_APP_API_URL}/courses/${id}`,
            {
                headers: {
                  
                    'Authorization': 'Bearer ' + this.state.token
                }
            })
            .then(res => {
                this.loadData()
                console.log(res.data)
            })
            .catch( err => {
                console.log(err)
            })
        }
        //console.log(id)
        
       
        
    }

    
   
    render() {
        return (
            <div className="container-fluid p-5">
                <h1>List of Courses </h1>
                <table className="table table-stripped">
                    
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Course Code</th>
                            <th>Title</th>
                            {/* <th>description</th> */}
                            {/* <th>Author</th> */}
                            <th>Image</th>
                            <th>Published Status</th>
                            
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                    
                      
                        { (this.state.courses.length > 0) && this.state.courses.map((course, index) => {
                            return (
                            <tr key={course.title}>
                                <td>{index+1}</td>
                                <td>PBL-{course.code}</td>
                                <td>{course.title}<br/><small>{course.author}</small></td>
                                {/* <td>{course.description}</td> */}
                                {/* <td>{course.author}</td> */}
                                <td><img src={`${process.env.PUBLIC_URL}/uploads/courses/course-${course.code}/${course.imgSrc}`} width="50px" height="50px" /> </td>
                                <td>{(course.published)? <span className="badge badge-success">Published</span>:<span class="badge badge-secondary">UnPublished</span>
}</td>
                               
                                <td><a href={`/admin/course/create/${course._id}`} ><i className="fas fa-edit text-primary"></i> </a>| <a href="#" id={course._id} onClick={e => this.deleteHandler(e, course._id)}><i className="fas fa-trash-alt text-danger"></i></a></td>
                            </tr>
                            )
                        })}
                    </tbody>
                </table>
                
            </div>
        )
    }
}

export default CourseList
