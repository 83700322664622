import React, { useState, useEffect } from 'react'
import axios from 'axios'
import OwlItem from './OwlItem'
export default function ChooseCourse() {
    const [courses, setCourses] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    const loadData = async () => {
        await axios.get(`${process.env.REACT_APP_API_URL}/courses/published`)
            .then(res => {
                //console.log(res.data.courses)
                setIsLoading(false)
                setCourses(res.data.courses)
            })
            .catch(err => {
                if (err.response)
                    console.log(err.response.data)
            })
    }
    //Testing 
    useEffect(() => {

        loadData()
        //console.log(tempCourses)

    }, [])

    return (
        <div>
            <div className="courses">
                <div className="container">
                    <div className="section_title text-center"><h2>Course Catalog</h2></div>
                    <div className="row">
				        <div className="col">
                            <div className="courses_slider_container">
						        <div className="owl-carousel owl-theme courses_slider">

                                    {courses.map((course, index) => {
                                        return <OwlItem course={course} key={index}/>  
                                    })}
                                            
                                            
                                         
                                    

                                    </div>
                                    <div className="courses_slider_nav courses_slider_prev trans_200"><i className="fa fa-angle-left" aria-hidden="true"></i></div>
						            <div className="courses_slider_nav courses_slider_next trans_200"><i className="fa fa-angle-right" aria-hidden="true"></i></div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
