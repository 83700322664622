import ReactCardFlip from 'react-card-flip';
import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

class CardRotate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isFlipped: false,
            
            audio:null,
            playing:false,
            items:this.props.data.conversations
        };
        this.handleClick = this.handleClick.bind(this)
        this.soundClick = this.soundClick.bind(this)
        this.nextHandler = this.nextHandler.bind(this)

    }
    
    componentDidMount = () => {
        
       
    }
    componentDidUpdate = (prevProps) => {

       
        if(prevProps.data !== this.props.data){
          //  console.log("Done")
            this.setState({
                items:this.props.data.conversations,
                isFlipped:false,
                playing:false
            })
        }
    
    }
    soundClick = (e,audio) => {
        e.preventDefault()
        

        audio.play()
        this.setState({
            playing:!this.state.playing
        })
    }
    handleClick(e, idx) {
        e.preventDefault();
        let newitems=this.state.items
        let newitem = newitems[idx]
        newitem.isFlipped = !newitem.isFlipped
        newitems[idx] = newitem
        this.setState({
            items:newitems
        })
        //this.setState(prevState => ({ isFlipped: !prevState.isFlipped }));

    }

    nextHandler = () => {

    }
    render() {
        const responsive = {
            superLargeDesktop: {
              // the naming can be any, depends on you.
              breakpoint: { max: 4000, min: 3000 },
              items: 1
            },
            desktop: {
              breakpoint: { max: 3000, min: 1024 },
              items: 1
            },
            tablet: {
              breakpoint: { max: 1024, min: 464 },
              items: 1
            },
            mobile: {
              breakpoint: { max: 464, min: 0 },
              items: 1
            }
          };

          const cardstyle={
              height:"400px",
              backgroundColor:"#fff",
              borderRadius:"20px"
          }
        return (
            <div>

            <h4>Set Of Dialogues: {this.props.data.topic}</h4>
            <br/>
            <Carousel responsive={responsive}>
                {   this.state.items.map((item, index) => {
                        return (
                            <div>
                                
                                <ReactCardFlip isFlipped={item.isFlipped} flipDirection="horizontal" flipSpeedBackToFront="1.0" flipSpeedFrontToBack="1.0">
                                            <div id="front">
                                                <div className="card shadow p-1 text-center" style={cardstyle}>
                                                    <div className="card-header">
                                                        Dialogue In Punjabi 
                                                    </div>
                                                    <div className="card-body">
                                                        {(item.audio) && <h1><a href="#"  onClick={e => this.soundClick(e, item.audio)}><i className={(this.state.playing)? "fas fa-pause-circle":"fas fa-volume-up text-primary"}></i></a> <span><i className="far fa-comment-dots float-right text-primary"></i></span></h1>}
                                                        {/* <audio controls autoplay>
                                                            <source src={`${process.env.PUBLIC_URL}/assets/audios/introduction.mp3`} type="audio/mpeg"/>
                                                            Your browser does not support the audio element.
                                                        </audio> */}

                                                        {/* <h5 className="card-title">Dialogue {this.state.item.id}</h5> */}
                                                        <h3 className="card-text"  style={{marginTop:"80px"}}>{item.punjabi}</h3>
                                                        <button style={{margin:"70px"}} onClick={(e) => this.handleClick(e,index)} className="btn btn-primary">Click to flip</button>
                                                    </div>
                                                    <div className="text-muted">
                                                        Card - {index+1} of {this.state.items.length}
                                                    </div>
                                                </div>
                                        {/* <button onClick={this.handleClick} classNameName="btn btn-primary">Click to flip</button> */}
                                            </div>

                                            <div id="back">
                                                <div className="card shadow p-1  text-center"  style={cardstyle}>
                                                    <div className="card-header">
                                                        Dialogue In English
                                                    </div>
                                                    <div className="card-body">
                                                         <h1><a href="#"  onClick={e => this.soundClick(e)}><i className={(this.state.playing)? "fas fa-pause-circle":"fas fa-volume-up text-primary"}></i></a></h1> 
                                                        
                                                        {/* <h5 className="card-title">Dialogue {this.state.item.id}</h5> */}
                                                        <h3 className="card-text" style={{marginTop:"80px"}}>{item.english}</h3>
                                                        <button  style={{marginTop:"70px"}}  onClick={(e) => this.handleClick(e,index)} className="btn btn-primary">Click to flip</button>
                                                    </div>
                                                    <div className="text-muted">
                                                        Card - {index+1} of {this.state.items.length}
                                                    </div>
                                                </div>
                                        
                                            </div>
                                    </ReactCardFlip>
                            </div>
                        )
                    })
                }
                
            </Carousel>
            </div>
        )
    }
}

export default CardRotate

