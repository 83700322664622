import React from 'react'
import Menu from './Menu'

export default function HeaderMain() {
    return (
        <div>
            <header className="header">

                <div className="top_bar" style={{backgroundColor:"#ff8a00"}}>
                    <div className="top_bar_container">
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <div className="top_bar_content d-flex flex-row align-items-center justify-content-start">
                                        <ul className="top_bar_contact_list">
                                            <li><div className="question" style={{color:"#fff"}}>Tegh Education Society, Canada</div></li>
                                            <li>
                                                <div className="question"  style={{color:"#fff"}}>Learn About Sikhism, Learn Punjabi</div>
                                            </li>
                                            <li>
                                                {/* <div  className="question"  style={{color:"#fff"}}>info@iknowpunjabi.com</div> */}
                                            </li>
                                        </ul>
                                        <div className="top_bar_login ml-auto">
                                            <span className="question"  style={{color:"#fff"}}><i class="fas fa-envelope-square"></i> contactus@iknowpunjabi.com</span>
                                            {/* <ul>
                                                <li><a href="#">Register</a></li>
                                                <li><a href="#">Login</a></li>
                                            </ul> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="header_container">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="header_content d-flex flex-row align-items-center justify-content-start">
                                    <div className="logo_container">
                                        <a href="/">
                                            <div className="logo_content d-flex flex-row align-items-end justify-content-start">
                                                <div className="logo_img"><img width="250px" src={`${process.env.PUBLIC_URL}/assets/img/logo.png`} alt="" /></div>
                                               
                                            </div>
                                        </a>
                                    </div>
                                    <nav className="main_nav_contaner ml-auto">
                                        <ul className="main_nav">
                                            <li className="active"><a href="/">home</a></li>
                                            <li><a href="/about-us">about us</a></li>
                                            <li><a href="/catalog">our courses</a></li>
                                            <li><a href="/donate">Donate</a></li>
                                            <li><a href="/contact-us">contact</a></li>
                                        </ul>
                                        {/* <div className="search_button"><i className="fa fa-search" aria-hidden="true"></i></div> */}


                                        <div className="hamburger menu_mm">
                                            <i className="fa fa-bars menu_mm" aria-hidden="true"></i>
                                        </div>
                                    </nav>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="header_search_container">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="header_search_content d-flex flex-row align-items-center justify-content-end">
                                    <form action="#" className="header_search_form">
                                        <input type="search" className="search_input" placeholder="Search" required="required" />
                                        <button className="header_search_button d-flex flex-column align-items-center justify-content-center">
                                            <i className="fa fa-search" aria-hidden="true"></i>
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <Menu/>
        </div>
    )
}
