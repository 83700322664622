import React from 'react'

export default function CategorySection() {
    return (
        <div>
            <section className="finance_area">
                <div className="container">
                    <div className="finance_inner row">
                        <div className="col-lg-3 col-sm-6">
                            <div className="finance_item">
                                <div className="media">
                                    <div className="d-flex">
                                        <i className="lnr lnr-rocket"></i>
                                    </div>
                                    <div className="media-body">
                                        <h5>Science & <br />Engineering</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="finance_item">
                                <div className="media">
                                    <div className="d-flex">
                                        <i className="lnr lnr-earth"></i>
                                    </div>
                                    <div className="media-body">
                                        <h5>Science & <br />Engineering</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="finance_item">
                                <div className="media">
                                    <div className="d-flex">
                                        <i className="lnr lnr-smile"></i>
                                    </div>
                                    <div className="media-body">
                                        <h5>Science & <br />Engineering</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="finance_item">
                                <div className="media">
                                    <div className="d-flex">
                                        <i className="lnr lnr-tag"></i>
                                    </div>
                                    <div className="media-body">
                                        <h5>Science & <br />Engineering</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
