import React from 'react'
import Footer from '../footers/Footer'
import Header2 from '../headers/Header2/Header2'
import HeaderMain from '../headers/headerMain/HeaderMain'
import Menu from '../headers/headerMain/Menu'
import Hero from '../heros/Hero'
import HomeHero from '../heros/HomeHero'
import Main from '../pages/course/Main'
import CategorySection from '../pages/home/CategorySection'
import ChooseCourse from '../pages/home/ChooseCourse'
import CourseArea from '../pages/home/CourseArea'
import Catalog from '../pages/course/Catalog'


import Testimonial from '../pages/home/Testimonial'
import HeroCatalog from '../heros/HeroCatalog'

export default function CatalogView() {
    return (
        <div  className="super_container">
            <HeaderMain/>
            <Menu/>
            <HeroCatalog/>            
           
            <Catalog/>
            
            <br/>
            <br/>
            {/* <img src={`${process.env.PUBLIC_URL}/assets/mytheme/images/about.jpg`} />
            <div className="home_container">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="home_content text-center">
                                    <div className="home_title">Punjabi Lessons</div>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br/>
                <br/> */}
            <Footer/>

            {/* <HomeHero/>
            <CategorySection/>
            <CourseArea/>
            <Testimonial/>
            <Footer/> */}   
        </div>
    )
}
