import React, {useState} from 'react'
import axios from 'axios'

import { Link, Redirect, useHistory } from "react-router-dom"
import {authenticate, isAuth } from '../helpers/Auth'

export default function Login({history}) {
    const [ formData, setFormData ] = useState({
        email:'',
        password:'',
        textChange:'Sign In'
    })
    const [show, setShow] = useState(false)
    const [errMessage, setErrMessage ] = useState('')
    const [infoMessage, setInfoMessage ] = useState('')

  //  let history = useHistory()
    const changeHandler = ( event ) => {
        let data = {...formData}
        data[event.target.name] = event.target.value
        setFormData(data)
       // console.log(formData)
     }

     const { email, password, textChange } = formData

     const handleSubmit = (event) => {
         event.preventDefault()
         setFormData({...formData, textChange:'Submitting...'})
         if(email && password){
                axios.post(`${process.env.REACT_APP_API_URL}/auth/login`,{
                    email,
                    password
                })
                .then(res => {
                    //console.log(res)
                    authenticate(res, () => {
                        setFormData({
                            ...formData,
                            email:'',
                            password:'',
                            textChange:'Submitted'
                        })
                    })
                    setInfoMessage(res.data.message)
                    //console.log(isAuth().role)
                    history.push('/admin')
                    isAuth() && isAuth().role === 'admin'
                    ? history.push('/admin')
                    : history.push('/course');
                })
                .catch( err => {
                    setFormData({
                        ...formData,
                      
                        textChange:'Submit'
                    })
                    setErrMessage(err.response.data)
                    setTimeout(() => {
                        setErrMessage('')
                    },5000)
                    console.log(err)
                })
        }else{
                console.log("Email and Password Missing")
        }
         

     }

    return (
        <div>
            <br/>
            <br/>
            <div className="container">


                <div className="row justify-content-center">

                    <div className="col-xl-10 col-lg-12 col-md-9">

                        <div className="card o-hidden border-0 shadow-lg my-5">
                            <div className="card-body p-0">

                                <div className="row">
                                    <div className="col-lg-6 d-none d-lg-block bg-login-image"></div>
                                    <div className="col-lg-6">
                                        <div className="p-5">
                                            <div className="text-center">
                                                <h1 className="h4 text-gray-900 mb-4">Welcome Back!</h1>
                                            </div>
                                            <br/>
                                            {/* Checking for Guest Admin and Normal User*/}
                                            {(isAuth()) ? (isAuth().role == 'admin') ? <Redirect to='/admin' /> : <Redirect to='/landing' /> : ''}
                                            { (errMessage) && 
                                                <div className="p-2 text-danger">{errMessage}</div>
                                            }
                                            { (infoMessage) && 
                                                <div className="p-2 text-success">{infoMessage}</div>
                                            }
                                            <br/>
                                            <form className="user">
                                                <div className="form-group">
                                                    <input 
                                                    type="email"
                                                    className="form-control form-control-user"
                                                    id="email"
                                                    name="email"
                                                    aria-describedby="emailHelp"
                                                    placeholder="Enter Email Address..." 
                                                    onChange = { event => changeHandler(event)}
                                                    required
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <input
                                                    type="password"
                                                    className="form-control form-control-user"
                                                    id="password"
                                                    name="password"
                                                    onChange = { event => changeHandler(event)}
                                                    required
                                                    placeholder="Password" 
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <div className="custom-control custom-checkbox small">
                                                        <input type="checkbox" className="custom-control-input" id="customCheck" />
                                                        <label className="custom-control-label" for="customCheck">Remember
                                            Me</label>
                                                    </div>
                                                </div>
                                                <a href="index.html" className="btn btn-primary btn-user btn-block" onClick={handleSubmit}>
                                                    Login
                                </a>
                                                {/* <hr />
                                                <a href="index.html" className="btn btn-google btn-user btn-block">
                                                    <i className="fab fa-google fa-fw"></i> Login with Google
                                </a>
                                                <a href="index.html" className="btn btn-facebook btn-user btn-block">
                                                    <i className="fab fa-facebook-f fa-fw"></i> Login with Facebook
                                </a> */}
                                            </form>
                                            <hr />
                                            <div className="text-center">
                                                <a className="small" href="forgot-password.html">Forgot Password?</a>
                                            </div>
                                            <div className="text-center">
                                                <a className="small" href="register.html">Create an Account!</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </div>

        </div>
    )
}
