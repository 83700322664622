import React from 'react'

export default function Header2() {
    return (
        <div>
            <header className="header">


                <div className="top_bar">
                    <div className="top_bar_container">
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <div className="top_bar_content d-flex flex-row align-items-center justify-content-start">
                                        <ul className="top_bar_contact_list">
                                            <li><div className="question">Have any questions?</div></li>
                                            <li>
                                                <div>(009) 35475 6688933 32</div>
                                            </li>
                                            <li>
                                                <div>info@elaerntemplate.com</div>
                                            </li>
                                        </ul>
                                        <div className="top_bar_login ml-auto">
                                            <ul>
                                                <li><a href="#">Register</a></li>
                                                <li><a href="#">Login</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="header_container">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="header_content d-flex flex-row align-items-center justify-content-start">
                                    <div className="logo_container">
                                        <a href="#">
                                            <div className="logo_content d-flex flex-row align-items-end justify-content-start">
                                                <div className="logo_img"><img src={`${process.env.PUBLIC_URL}/assets/img/logo.png`} alt="" /></div>
                                            </div>
                                        </a>
                                    </div>
                                    <nav className="main_nav_contaner ml-auto">
                                        <ul className="main_nav">
                                            <li><a href="index.html">home</a></li>
                                            <li className="active"><a href="about.html">about us</a></li>
                                            <li><a href="courses.html">courses</a></li>
                                            <li><a href="news.html">news</a></li>
                                            <li><a href="contact.html">contact</a></li>
                                        </ul>
                                        <div className="search_button"><i className="fa fa-search" aria-hidden="true"></i></div>


                                        <div className="hamburger menu_mm">
                                            <i className="fa fa-bars menu_mm" aria-hidden="true"></i>
                                        </div>
                                    </nav>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="header_search_container">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="header_search_content d-flex flex-row align-items-center justify-content-end">
                                    <form action="#" className="header_search_form">
                                        <input type="search" className="search_input" placeholder="Search" required="required" />
                                        <button className="header_search_button d-flex flex-column align-items-center justify-content-center">
                                            <i className="fa fa-search" aria-hidden="true"></i>
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    )
}
