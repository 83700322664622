import React, {useState, useEffect} from 'react'
import axios from 'axios'
export default function Activate(props) {
    const [token, setToken] = useState(null)
    useEffect(() => {
        setToken(props.match.params.id)
    })

    const [info, setInfo] = useState(null)
    const [error, setError] = useState(null)

    const clickHandler = (e) => {
        e.preventDefault()
        axios.post(`${process.env.REACT_APP_API_URL}/auth/activate`,{
            token:token
        })
        .then(res => {
            setError('')
            setInfo(res.data.message)
           

        })
        .catch(err => {
            setInfo('')
            setError(err.response.data)
            
        })
    }

    return (
        <div>
            <br/>
            <br/>
            <div className="container">


                <div className="row justify-content-center">

                    <div className="col-xl-10 col-lg-12 col-md-9">

                        <div className="card o-hidden border-0 shadow-lg my-5">
                            <div className="card-body p-0">

                                <div className="row">
                                    <div className="col-lg-6 d-none d-lg-block bg-login-image"></div>
                                    <div className="col-lg-6">
                                        <div className="p-5">
                                            <div className="text-center">
                                                <span><img src={`${process.env.PUBLIC_URL}/assets/img/logo1.png`} /></span>
                                            <br/>
                                                <h1 className="h4 text-gray-900 mb-4">Welcome Back!</h1>
                                            </div>
                                            <form className="user">
                                                 {(info) && <div className="p-2 text-success">{info} <a href="/auth/login">Click Here To Login</a></div>}
                                                 {(error) && <div className="p-2  text-danger">{error}</div>}
                                                 <br/>
                                                <a href="index.html" className="btn btn-primary btn-user btn-block" onClick={e => clickHandler(e)}>
                                                    Click To Activate Your Account
                                                </a>
                                                {/* <hr />
                                                <a href="index.html" className="btn btn-google btn-user btn-block">
                                                    <i className="fab fa-google fa-fw"></i> Login with Google
                                </a>
                                                <a href="index.html" className="btn btn-facebook btn-user btn-block">
                                                    <i className="fab fa-facebook-f fa-fw"></i> Login with Facebook
                                </a> */}
                                            </form>
                                            
                                            {/* <div className="text-center">
                                                <a className="small" href="forgot-password.html">Forgot Password?</a>
                                            </div>
                                            <div className="text-center">
                                                <a className="small" href="register.html">Create an Account!</a>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </div>

        </div>
    )
}
