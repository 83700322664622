import React from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from "@stripe/stripe-js"
import PaymentForm from './PaymentForm'
import PaymentMethod from './PaymentMethod'

const PUBLIC_KEY = "pk_test_LG76pRPwVA91W2RzRUaFKj3x00zECUazO3" 
// const stripeTestPromise = loadStripe(PUBLIC_KEY)
export default function StripeContainer() {
    return (
        <div>
            <PaymentMethod/>
            
           </div>
    )
}
