import React, {useState, useEffect, useRef} from 'react'
import {getCookie} from '../helpers/Auth'
import JoditEditor from "jodit-react";

import axios from 'axios'
export function NewCourse() {
    const [course, setCourse] = useState({
        code:'',
        title:'',
        shortDesc:'',
        description:'',
        imgSrc:null,
        author:'Tegh Education Society, Canada',
        lessons:[],
        published:false
        
    })
    const editor = useRef(null)
	const [content, setContent] = useState('')
    const [token , setToken ] = useState(null)
    const [info, setInfo] = useState(null)
    const [error, setError] = useState(null)

    useEffect(() => {
        let storedToken = getCookie('token')

        if(storedToken){
            setToken(storedToken)
        }
    })
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + token
        }
    }
    const changeHandler = (e) => {
        course[e.target.name] = e.target.value
        if(e.target.name == 'imgSrc'){
            
            course[e.target.name] = e.target.files[0]
        }
        setCourse({...course})
        //console.log(course)
    }
    const submitHandler = (e) => {

        e.preventDefault()
        if(course.description){
            course.description = content
        }
        
       // console.log("Test"+content)
        const formData = new FormData()
        formData.append("code",course.code)
        formData.append("title",course.title)
        formData.append("shortDesc",course.shortDesc)
        formData.append("description", content)
        formData.append("author",course.author)
        formData.append(
            "imgSrc",
            course.imgSrc,
           
        )
       
       
        axios.post(`${process.env.REACT_APP_API_URL}/courses/new`, 
            formData,            
            config
        )
        .then(res => {
            setError(null)
           
            setCourse({
                code:'',
                title:'',
                shortDesc:'',
                description:'',
                imgSrc:null,
                author:'Tegh Education Society, Canada',
                lessons:[],
                published:false
                
            })
            setInfo(res.data)
            //console.log(res.data)
        })
        .catch(err => {
            setInfo(null)
           // console.log(err.response.data)
           if(err.response){
            setError(err.response.data)
           }
            
        })
    }


    const config3 = {
		readonly: false // all options from https://xdsoft.net/jodit/doc/
	}
	
    const {code, title, description, imgSrc,shortDesc, author} = course
    return (
        <div className="bg-light p-5">
           <div className="container">
               <h3>Add New Course</h3>
               <hr/>
               { (info) && <div className="bg-success p-2 text-white">{info}</div>}
               { (error) && <div className="bg-danger p-2 text-white">{error}</div>}
               

               <form onSubmit={e => submitHandler(e)}>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Enter the course code starting from 100 onwards</label>
                        <input 
                        type="number"
                        name="code"
                        className="form-control"
                        id="code"
                        aria-describedby="emailHelp"
                        placeholder="Enter Code Of the Course In Numeric. 101, 102 etc"
                        onChange = {e => changeHandler(e)}
                        value={code}
                        required
                        
                        />
                        <small id="emailHelp" className="form-text text-muted">Title of the course to be displayed on the website</small>
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Name of The Course</label>
                        <input 
                        type="text"
                        name="title"
                        className="form-control"
                        id="title"
                        aria-describedby="emailHelp"
                        placeholder="Enter Name Of the Course"
                        onChange = {e => changeHandler(e)}
                        value={title}
                        required
                        
                        />
                        <small id="emailHelp" className="form-text text-muted">Title of the course to be displayed on the website</small>
                    </div>

                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Short Description</label>
                        <input 
                        type="text"
                        name="shortDesc"
                        className="form-control"
                        id="shortDesc"
                        aria-describedby="emailHelp"
                        placeholder="Enter Name Of the Course"
                        onChange = {e => changeHandler(e)}
                        value={shortDesc}
                        required
                        
                        />
                        <small id="emailHelp" className="form-text text-muted">Short Description of the course to be displayed on the website</small>
                    </div>

                    <div className="form-group">
                        <label htmlFor="exampleInputPassword1">Description Of the Course</label>
                        {/* <textarea
                         className="form-control"
                         name="description"
                         id="description"
                         placeholder="Description"
                         rows="5"
                         cols="10"
                         onChange = {e => changeHandler(e)}
                         value={description}
                         required
                         >
                        
                         </textarea> */}
                        <JoditEditor
                            ref={editor}
                            value={content}
                            config={config3}
                            id="description"
                            name="description"
                            tabIndex={1} // tabIndex of textarea
                            onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                           // onChange = {e => changeHandler(e)}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleFormControlFile1">Upload Course Feature Image</label>
                        <input 
                        type="file" 
                        className="form-control-file" 
                        name="imgSrc" 
                        id="imgSrc"
                        onChange = {e => changeHandler(e)}
                        required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Author Of The Course Content</label>
                        <input 
                        type="text"
                        name="author"
                        className="form-control"
                        id="author"
                        aria-describedby="emailHelp"
                        placeholder="Enter Author Of the Course"
                        onChange = {e => changeHandler(e)}
                        required
                        value={author}
                        />
                        <small id="emailHelp" className="form-text text-muted">Author of the content</small>
                    </div>
                   
                    <button type="submit" className="btn btn-primary">Submit</button>   | <a href="/admin/course-list" className="btn btn-small btn-primary">View Course List</a>
                </form>
           </div>
        </div>
    )
}

export default NewCourse
