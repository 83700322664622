import React, {useState, useEffect, useRef} from 'react'
import {getCookie} from '../helpers/Auth'
import JoditEditor from "jodit-react";

import axios from 'axios'
export function EditCourse(props) {
    const [course, setCourse] = useState({
        code:'',
        title:'',
        shortDesc:'',
        description:'',
        imgSrc:null,
        author:'Tegh Education Society, Canada',
        published:false
        
    })
    const editor = useRef(null)
	const [content, setContent] = useState('')
    const [token , setToken ] = useState(null)
    const [info, setInfo] = useState(null)
    const [error, setError] = useState(null)
    
    const loadData = async (id) => {
        //console.log(config2)
        await axios.get(`${process.env.REACT_APP_API_URL}/courses/view/${id}`,
            config2
        )
        .then( res => {
            console.log(res.data)
            setError('')
            setContent(res.data.course.description)
            setCourse(res.data.course)
            
        })
        .catch(err => {
            console.log(err)
            setInfo('')
            setError(err.response.data)

        })

    }
    useEffect(() => {
        
        let storedToken = getCookie('token')

        if(storedToken){
            setToken(storedToken)
        }
        //console.log(storedToken)
        loadData(props.match.params.id)
    },[])
    const config2 = {
        headers: {
            
            'Authorization': 'Bearer ' + getCookie('token')
        }
    }
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + token
        }
    }
    const changeHandler = (e) => {
        course[e.target.name] = e.target.value
        if(e.target.name == 'imgSrc'){
            
            course[e.target.name] = e.target.files[0]
        }

        if(e.target.name == "published"){
            if(e.target.value == "0"){
                course[e.target.name] = false
            }else{
                course[e.target.name] = true
            }
        }
        setCourse({...course})
        //console.log(course)
    }

    const config3 = {
		readonly: false // all options from https://xdsoft.net/jodit/doc/
	}
	
    const submitHandler = (e) => {

        e.preventDefault()
        if(course.description){
            course.description = content
        }
        //console.log(token)
        const formData = new FormData()
        formData.append("code",course.code)
        formData.append("title",course.title)
        formData.append("shortDesc",course.shortDesc)
        formData.append("description", course.description)
        formData.append("author",course.author)
        formData.append("published",course.published)
        formData.append(
            "imgSrc",
            course.imgSrc,
           
        )
       
       
        axios.put(`${process.env.REACT_APP_API_URL}/courses/update/${props.match.params.id}`, 
            formData,            
            config
        )
        .then(res => {
            setError(null)
            setInfo(res.data)
            //console.log(res.data)
        })
        .catch(err => {
            setInfo(null)
           // console.log(err.response.data)
            setError(err.response.data)
        })
    }

    const {code, title, description, imgSrc, author, shortDesc, published} = course
    return (
        <div className="bg-light p-5">
           <div className="container">
               <h3>Edit The Selected Course (PBL-{code} {title})</h3>
               <hr/>
               { (info) && <div className="bg-success p-2 text-white">{info}</div>}
               { (error) && <div className="bg-danger p-2 text-white">{error}</div>}
               

               <form onSubmit={e => submitHandler(e)}>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Enter the course code starting from 100 onwards</label>
                        <input 
                        type="number"
                        name="code"
                        className="form-control"
                        id="code"
                        aria-describedby="emailHelp"
                        placeholder="Enter Code Of the Course In Numeric. 101, 102 etc"
                        onChange = {e => changeHandler(e)}
                        value={code}
                        required
                        readOnly
                        />
                        <small id="emailHelp" className="form-text text-muted">Title of the course to be displayed on the website</small>
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Name of The Course</label>
                        <input 
                        type="text"
                        name="title"
                        className="form-control"
                        id="title"
                        aria-describedby="emailHelp"
                        placeholder="Enter Name Of the Course"
                        onChange = {e => changeHandler(e)}
                        value={title}
                        required
                        
                        />
                        <small id="emailHelp" className="form-text text-muted">Title of the course to be displayed on the website</small>
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Short Description</label>
                        <input 
                        type="text"
                        name="shortDesc"
                        className="form-control"
                        id="shortDesc"
                        aria-describedby="emailHelp"
                        placeholder="Enter Name Of the Course"
                        onChange = {e => changeHandler(e)}
                        value={shortDesc}
                        required
                        
                        />
                        <small id="emailHelp" className="form-text text-muted">Short Description of the course to be displayed on the website</small>
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputPassword1">Description Of the Course</label>
                        <JoditEditor
                            ref={editor}
                            value={content}
                            config={config3}
                            id="description"
                            name="description"
                            tabIndex={1} // tabIndex of textarea
                            onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                           // onChange = {e => changeHandler(e)}
                        />
                        {/* <textarea
                         className="form-control"
                         name="description"
                         id="description"
                         placeholder="Description"
                         rows="5"
                         cols="10"
                         onChange = {e => changeHandler(e)}
                         value={description}
                         required
                         >
                        
                         </textarea> */}
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleFormControlFile1">Uploaded Image</label>
                        <input 
                        type="file" 
                        className="form-control-file" 
                        name="imgSrc" 
                        id="imgSrc"
                        onChange = {e => changeHandler(e)}
                        
                        />
                        <img src={`${process.env.PUBLIC_URL}/uploads/courses/course-${course.code}/${imgSrc}`} width="100px" height="100px" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Author Of The Course Content</label>
                        <input 
                        type="text"
                        name="author"
                        className="form-control"
                        id="author"
                        aria-describedby="emailHelp"
                        placeholder="Enter Author Of the Course"
                        onChange = {e => changeHandler(e)}
                        required
                        value={author}
                        />
                        <small id="emailHelp" className="form-text text-muted">Author of the content</small>
                    </div>

                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Select to publish the course</label>
                            <select id="published" name="published" className="form-control" onChange = {e => changeHandler(e)}>
                                <option value="0" selected={(published == false) ? true:false} >UnPublished</option>
                                <option value="1" selected={(published == true) ? true:false}>Published</option>
 
                            </select>
                        </div>
                   
                    <button type="submit" className="btn btn-primary">Update Course</button>  | <a href="/admin/course-list" className="btn btn-small btn-primary">View Course List</a>
                </form>
           </div>
        </div>
    )
}

export default EditCourse
