import React, { Component } from 'react'
import Carousel from "react-multi-carousel";
import Card from './Card';

export class MyCarousel extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             
        }
    }
    
    render() {
        const responsive = {
            superLargeDesktop: {
              // the naming can be any, depends on you.
              breakpoint: { max: 4000, min: 3000 },
              items: 1
            },
            desktop: {
              breakpoint: { max: 3000, min: 1024 },
              items: 1
            },
            tablet: {
              breakpoint: { max: 1024, min: 464 },
              items: 1
            },
            mobile: {
              breakpoint: { max: 464, min: 0 },
              items: 1
            }
          };
        return (
            <Carousel responsive={responsive}>
                {   this.props.data.conversations.map((item, index) => {
                    return (<div>
                            <Card data={item.punjabi}/>
                    </div>)
                })
                }
             </Carousel>
        )
    }
}

export default MyCarousel
