import React from 'react'

export default function Menu() {
    return (
        <div>
            <div className="menu d-flex flex-column align-items-end justify-content-start text-right menu_mm trans_400">
                <div className="menu_close_container"><div className="menu_close"><div></div><div></div></div></div>
                <div className="search">
                    <form action="#" className="header_search_form menu_mm">
                        <input type="search" className="search_input menu_mm" placeholder="Search" required="required" />
                        <button className="header_search_button d-flex flex-column align-items-center justify-content-center menu_mm">
                            <i className="fa fa-search menu_mm" aria-hidden="true"></i>
                        </button>
                    </form>
                </div>
                <nav className="menu_nav">
                    <ul className="menu_mm">
                        <li className="menu_mm"><a href="/">Home</a></li>
                        <li className="menu_mm"><a href="/about-us">About Us</a></li>

                        {/* <li className="menu_mm"><a href="#">Instructors</a></li> */}
                        <li className="menu_mm"><a href="/catalog">Our Courses</a></li>
                        <li className="menu_mm"><a href="/donate">Donate</a></li>
                        <li className="menu_mm"><a href="/contact-us">Contact</a></li>
                    </ul>
                </nav>
                <div className="menu_extra">
                    <div className="menu_phone"><span className="menu_title">phone:</span>(009) 35475 6688933 32</div>
                    <div className="menu_social">
                        <span className="menu_title">follow us</span>
                        <ul>
                            <li><a href="#"><i className="fa fa-pinterest" aria-hidden="true"></i></a></li>
                            <li><a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                            <li><a href="#"><i className="fa fa-instagram" aria-hidden="true"></i></a></li>
                            <li><a href="#"><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
