import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { getCookie } from  '../helpers/Auth'

export default function Index() {

    const [data, setData] = useState({})
    const [token, setToken] = useState(null)
    const config = {
        headers: {
            
            'Authorization': 'Bearer ' + getCookie('token')

        }
    }
      const loadData = async () => {
        

        await axios.get(`${process.env.REACT_APP_API_URL}/admin/dashboard`, config)
          .then(res => {
  
             console.log("On load: "+res.data.donationAmount)
              setData({
                  queriesCount: res.data.queriesCount,
                  subscriptionCount: res.data.subscriptionCount,
                  courseCount:res.data.courseCount,
                  donationAmount:res.data.donationAmount
              })
             
          })
          .catch(err => console.log(err.message))
      }
  

    useEffect(() => {
        loadData()
        
      
        
    },[])
    
    return (
        <div>
            <div className="container-fluid">

                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 text-gray-800">Dashboard</h1>
                    {/* <a href="/" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i
                        className="fas fa-download fa-sm text-white-50"></i> Generate Report</a> */}
                </div>

                <div className="row">

                    <div className="col-xl-3 col-md-6 mb-4">
                        <div className="card border-left-primary shadow h-100 py-2">
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                            Total Courses</div>
                                        <div className="h5 mb-0 font-weight-bold text-gray-800">{data.courseCount && data.courseCount}</div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-calendar fa-2x text-gray-300"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-3 col-md-6 mb-4">
                        <div className="card border-left-success shadow h-100 py-2">
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                                            Contact Messages</div>
                                        <div className="h5 mb-0 font-weight-bold text-gray-800">{data.queriesCount && data.queriesCount}</div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-3 col-md-6 mb-4">
                        <div className="card border-left-info shadow h-100 py-2">
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-info text-uppercase mb-1">Total Donation
                        </div>
                                        <div className="row no-gutters align-items-center">
                                            <div className="col-auto">
                                                <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">${data.donationAmount && data.donationAmount}</div>
                                            </div>
                                            <div className="col">
                                                <div className="progress progress-sm mr-2">
                                                    <div className="progress-bar bg-info" role="progressbar"
                                                        aria-valuenow="50" aria-valuemin="0"
                                                        aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-clipboard-list fa-2x text-gray-300"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="col-xl-3 col-md-6 mb-4">
                        <div className="card border-left-warning shadow h-100 py-2">
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                                            Subscribers</div>
                                        <div className="h5 mb-0 font-weight-bold text-gray-800">{data.subscriptionCount && data.subscriptionCount}</div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-comments fa-2x text-gray-300"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>


                <div className="row">

                   
                </div>

                

            </div>
        </div>
    )
}
