import React from 'react'

export default function ColumnRIght() {
    return (
        <div>
            <div className="contact_map">

                <div id="google_map" className="google_map">
                    <div className="map_container">
                        <div id="map"></div>
                    </div>
                </div>

            </div>
        </div>
    )
}
