import ReactCardFlip from 'react-card-flip';
import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

class LessonCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isFlipped: false,
            
            audio:null,
            playing:false,
            items:this.props.data.dialogues
        };
        this.handleClick = this.handleClick.bind(this)
        this.soundClick = this.soundClick.bind(this)
        this.nextHandler = this.nextHandler.bind(this)

    }
    
    componentDidMount = () => {
        
       
    }
    componentDidUpdate = (prevProps) => {

       
        if(prevProps.data !== this.props.data){
          //  console.log("Done")
            this.setState({
                items:this.props.data.dialogues,
                isFlipped:false,
                playing:false
            })
        }
    
    }
    soundClick = (e,audio) => {
        e.preventDefault()
        //console.log(audio)
        if(!this.state.playing){
            audio.play()
        }
        
        this.setState({
            playing:!this.state.playing
        })
        audio.onended = () => {
            this.setState({
                playing:false
            })
        }
    }
    handleClick(e, idx) {
        e.preventDefault();
        let newitems=this.state.items
        let newitem = newitems[idx]
        newitem.isFlipped = !newitem.isFlipped
        newitems[idx] = newitem
        this.setState({
            items:newitems
        })
        //this.setState(prevState => ({ isFlipped: !prevState.isFlipped }));

    }

    nextHandler = () => {

    }
    render() {
        const responsive = {
            superLargeDesktop: {
              // the naming can be any, depends on you.
              breakpoint: { max: 4000, min: 3000 },
              items: 1
            },
            desktop: {
              breakpoint: { max: 3000, min: 1024 },
              items: 1
            },
            tablet: {
              breakpoint: { max: 1024, min: 464 },
              items: 1
            },
            mobile: {
              breakpoint: { max: 464, min: 0 },
              items: 1
            }
          };

          const cardstyle={
              height:"400",
              backgroundColor:"#efe",
              borderRadius:"10px"
          }
        return (
            <div className="container">
            
            <h4>Lesson-{this.props.lessonId} : {this.props.data.topic}</h4>
            <hr/>
            <h5>Read the dialogue. Click on a speech bubble to get a pronunciation or grammar tip. </h5>
            <br/>
            <Carousel responsive={responsive}>
                
                {   this.state.items.map((item, index) => {
                        let soundObject = new Audio(`${process.env.PUBLIC_URL}/uploads/courses/course-${this.props.courseCode}/lesson-${this.props.lessonId}/${item.audio}`)
                        //console.log(audioObj)
                        return (
                            <div key={index}>
                                
                                <ReactCardFlip isFlipped={item.isFlipped} flipDirection="horizontal" flipSpeedBackToFront="1.0" flipSpeedFrontToBack="1.0">
                                            <div id="front">
                                                <div className="card  p-1 text-center" style={cardstyle}>
                                                    {/* <div className="card-header">
                                                       <h3> {item.pbInEng} </h3>
                                                    </div> */}
                                                    <div className="card-body">
                                                        
                                                        {(true) && <h1><a href="#"  onClick={e => this.soundClick(e, soundObject)}><i className={(this.state.playing)? "fas fa-pause-circle":"fas fa-volume-up text-primary"}></i></a> </h1>}
                                                        {/* <audio controls autoplay>
                                                            <source src={`${process.env.PUBLIC_URL}/assets/audios/introduction.mp3`} type="audio/mpeg"/>
                                                            Your browser does not support the audio element.
                                                        </audio> */}

                                                        {/* <h5 className="card-title">Dialogue {this.state.item.id}</h5> */}
                                                        <h3 className="card-text"  style={{marginTop:"30px"}}>{item.punjabi}</h3>
                                                        <h3 className="card-text" style={{marginTop:"30px"}}> {item.pbInEng} </h3>                                                        
                                                        <button style={{marginTop:"40px"}} onClick={(e) => this.handleClick(e,index)} className="btn btn-primary">Click to flip</button>
                                                    </div>
                                                    <div className="text-muted">
                                                        Card - {index+1} of {this.state.items.length}
                                                    </div>
                                                </div>
                                        {/* <button onClick={this.handleClick} classNameName="btn btn-primary">Click to flip</button> */}
                                            </div>

                                            <div id="back">
                                                <div className="card  p-1  text-center"  style={cardstyle}>
                                                    {/* <div className="card-header">
                                                        <h3>Dialogue In English</h3>
                                                    </div> */}
                                                    <div className="card-body">
                                                         {/* <h1><a href="#"  onClick={e => this.soundClick(e)}><i className={(this.state.playing)? "fas fa-pause-circle":"fas fa-volume-up text-primary"}></i></a></h1>  */}
                                                        
                                                        {/* <h5 className="card-title">Dialogue {this.state.item.id}</h5> */}
                                                        <h3 className="card-text" style={{marginTop:"105px"}}>{item.english}</h3>
                                                        <button  style={{marginTop:"88px"}}  onClick={(e) => this.handleClick(e,index)} className="btn btn-primary">Click to flip</button>
                                                    </div>
                                                    <div className="text-muted">
                                                        Card - {index+1} of {this.state.items.length}
                                                    </div>
                                                </div>
                                        
                                            </div>
                                    </ReactCardFlip>
                            </div>
                        )
                    })
                }
                
            </Carousel>
            </div>
        )
    }
}

export default LessonCard

