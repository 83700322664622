import React from 'react'

export default function OwlItem() {
    return (
        <div>
            <div className="owl-item">
                <div className="course">
                    <div className="course_image"><img src={`${process.env.PUBLIC_URL}/assets/mytheme/images/course_1.jpg`} alt="" /></div>
                    <div className="course_body">
                        <div className="course_header d-flex flex-row align-items-center justify-content-start">
                            <div className="course_tag"><a href="#">Featured</a></div>
                            <div className="course_price ml-auto">Price: <span>Free</span></div>
                        </div>
                        <div className="course_title"><h3><a href="courses.html">Know Punjabi Basics</a></h3></div>
                        <div className="course_text">Maecenas rutrum viverra sapien sed ferm entum. Morbi tempor odio eget lacus tempus pulvinar.</div>
                        <div className="course_footer d-flex align-items-center justify-content-start">
                            {/* <div className="course_author_image"><img src={`${process.env.PUBLIC_URL}/assets/mytheme/images/featured_author.jpg`} alt="https://unsplash.com/@anthonytran"/></div> */}
                            <div className="course_author_name">By <a href="#">Tegh Education Society, Canada</a></div>
                            <div className="course_sales ml-auto"><span>352</span> Views</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
