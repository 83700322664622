import React, { useState, useEffect, Component } from 'react'
import axios  from 'axios'
import { getCookie } from '../../helpers/Auth'
import { config } from 'dotenv'
export default function ViewQuestions() {
    const [courses, setCourses] = useState([])
    const [lessons, setLessons] = useState([])
    const [practice, setPractice] = useState({
        id:null,
        question:'',
        options:[],
        correct:'',
        type:'mcq'
    }) 
    const [token, setToken] = useState(null)
    const [selectedCourse, setSelectedCourse] = useState(null)
    const [lessonId, setLessonId] = useState(null)

    const [info, setInfo] = useState(null)
    const [error, setError] = useState(null)

    const loadData = async () => {
        let response = await axios.get(`${process.env.REACT_APP_API_URL}/courses/list`)
        setCourses(response.data.courses)
    }

    useEffect(() => {
        loadData()
        

    }, [])
    
    useEffect(() => {
          // Do something on lessons change

    },[lessons])
    const handleLessonSelect = (e) => {
        if(e.target.value !== 'NA'){
            let tempLesson = selectedCourse.lessons[e.target.value]
           // console.log(tempLesson)
            setLessonId(e.target.value)
            if(tempLesson.practices){
                setPractice({...practice, id:tempLesson.practices.length + 1})
               
                //console.log("Question Exist")

            }else{
                setPractice({...practice, id:1})

                //console.log("Question Missing")
            }
            // setLesson({ ...lesson, id: courses[e.target.value].lessons.length + 1 })
            // console.log(courses[e.target.value])
        }
    }

    const handleSelect = async (e) => {
       
        if(e.target.value !== 'NA'){
            console.log(e.target.value)
            setSelectedCourse(courses[e.target.value])
            await axios.get(`${process.env.REACT_APP_API_URL}/courses/${courses[e.target.value]._id}/lessons`)
            .then(res => {
                const course = res.data.course
                //console.log(course.title)
                setLessons(res.data.course.lessons)

            })
            .catch(err => {
                if(err.response){
                    console.log(err.response.data)
                }
                
            })        

        }else{
            setSelectedCourse(null)
            setLessons([])
        }
        
       // console.log(courses[e.target.value])
    }

    const submitHandler = (e) => {
        e.preventDefault()
    }
    const config = {
        headers: {
            
            'Authorization': 'Bearer ' + getCookie('token')
        }
    }
    const handleDelete = (index) => {
	  let x =  window.confirm('Do you really want to Delete this record?')
        if(x){
       
		axios.delete(`${process.env.REACT_APP_API_URL}/courses/${selectedCourse._id}/lessons/${lessonId}/practices/${index}`,config)
		.then(res => {
		    setInfo(res.data.message)
		    setError('')
		   // setLessons(res.data.course)
		    setSelectedCourse(res.data.course)
		})
		.catch(err => {
		    setInfo('')
		    
		    // if(err.response){
		    //     setError(err.response.data)
		    //     console.log(err.response.data)
		    // }
		       
		})
	}
    }
    return (
        <div className="container-fluid p-5">
            <h3>View Questions For a Particular Course    </h3>
            <hr/>
            { (info) && <div className="p-2 text-success">{info}<br/></div>}
               { (error) && <div className="p-2 text-danger">{error}<br/></div>}
             <form onSubmit={e => submitHandler(e)}>
                <div className="form-group">
                    <label htmlFor="exampleFormControlSelect1">Select The Course To View Question Details</label>
                    <select className="form-control" id="code" name="code" onChange={e => handleSelect(e)}>
                        <option value='NA'>Please Select...</option>
                        {courses.map((course, index) => {
                            return <option value={index} key={index}>PBL-{course.code} {course.title}</option>
                        })}
                    </select>
                </div>
                { selectedCourse && <div className="form-group">
                        <label htmlFor="exampleFormControlSelect1">Select The Course</label>
                        <select className="form-control" id="lessonId" name="lessonId" onChange={e => handleLessonSelect(e)}>
                            <option value="NA">Please Select...</option>
                            {selectedCourse.lessons.map((lesson, index) => {
                                return <option value={index} key={index}> {lesson.topic}</option>
                            })}
                        </select>
                    </div>
                    }
                <hr/>


            </form>
            { (selectedCourse) && <table class="table stripped">
                <thead className="thead-dark">
                    <tr>
                    <th scope="col">#</th>
                    <th scope="col">Question</th>
                    <th scope="col">Options</th>
                    <th scope="col">Correct</th>
                    <th scope="col">Type</th>
                    <th scope="col">Action</th>
                    
                    </tr>
                </thead>
                <tbody>
                    {
                        lessonId && selectedCourse.lessons[lessonId].practices.map((practice, index) => {
                            return <tr key={index}>
                                <td>{index+1}</td>
                                <td><div dangerouslySetInnerHTML={{__html: practice.question}}></div></td>
                                <td><ul>{practice.options.map(option => {
                                    return <li>{option}</li>
                                })}</ul></td>
                                <td>{practice.correct}</td>
                                <td>{practice.type}</td>
                                <td><a href={`/admin/courses/${selectedCourse._id}/lessons/${lessonId}/practices/index`} ><i className="fas fa-edit text-primary"></i> </a> | <a href='#' onClick={() => handleDelete(index)}>Delete</a></td>
                            </tr>
                        })
                    }

                </tbody>
            </table> }

           
            
        </div>
    )
}
