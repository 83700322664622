import React,{useState, useEffect} from 'react'
import axios from 'axios'

export default function Sanjhiwalta() {

    const [content, setContent] = useState('')
    
    const loadData = async () => {
        await axios.get(`${process.env.REACT_APP_API_URL}/pages/sanjhiwalta`)
          .then(res => {
  
              //console.log("On load: "+res.data.content)
              setContent(res.data.content)
              //console.log(content)
          })
          .catch(err => console.log(err.message))
      }
  
      useEffect(() => {
         loadData()
        
      },[])
    return (
        <div className=" bg-light p-5">
        <br/>
        <br/>
          <div className="container bg-light">
                <div className="row">
                    <div dangerouslySetInnerHTML={{__html: content}} />
                </div>
                
            </div>
            <div className="button join_button"><a href="#">Know More About Sanjhiwalta<div className="button_arrow"><i className="fa fa-angle-right" aria-hidden="true"></i></div></a></div>
    </div>
)
    
}
