import React, { useState, useEffect } from 'react'
import CardRotate from './CardRotate.js'
import MyCarousel from './MyCarousel.js'
import axios from 'axios'
export default function Main(props) {

    const [course, setCourse] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    const loadData = async () => {
        await axios.get(`${process.env.REACT_APP_API_URL}/courses/web/${props.course_id}`)
            .then(res => {
                console.log(res.data.course)
                setIsLoading(false)
                setCourse(res.data.course)
            })
            .catch(err => {
                if (err.response)
                    console.log(err.response.data)
            })
    }
    const [courseData, setCourseData] = useState([])
    const [item, setItem] = useState(null)
    const [show, setShow] = useState(false)
    const [id, setId] = useState(null)

    useEffect(() => {
        loadData()

        // setCourseData(lessons)

    }, [])

    const clickHandler = (e, idx) => {

        setId(idx)
        setShow(true)
        //console.log(idx)
        //setItem(lessons[idx])
        // console.log(item)
    }
    return (
        <div>
         	
           { course && <div className="news">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="news_posts">
                                <div className="news_post">
                                    <div className="news_post_body" id="viewCard">
                                        <div className="news_post_date"><a href="#">{course.timestamp}</a></div>
                                        <div className="news_post_title"><a href="#">{course.title}</a> </div>
                                        <div className="news_post_meta d-flex flex-row align-items-start justify-content-start">
                                            <div className="news_post_author">By <a href="#">{course.author}</a></div>

                                            {/* <div className="news_post_comments"><a href="#"></a></div>
                                                <div className="news_post_tags">
                                                    <span>in </span>
                                                    <ul>
                                                        <li><a href="#">Social Media</a></li>
                                                    </ul>
                                                </div> */}
                                        </div>
                                        <div ><img className="img-fluid" src={`${process.env.PUBLIC_URL}/uploads/courses/course-${course.code}/${course.imgSrc}`} alt="" /></div>

                                        <div className="news_post_text">
                                            <br />
                                            <p><div dangerouslySetInnerHTML={{ __html: course.description }}></div></p>
                                        </div>
                                        <div className="news_post_link"><a href="#">Read More</a></div>
                                    </div>
                                </div>

                                <div className="news_post">
                                    {(show) && <CardRotate data={item} />}
                                    {(show) && <MyCarousel data={item} />}


                                </div>
                            </div>

                        </div>


                    </div>
                    <div className="row">
                        <div className="col-md-12">

                            <div className="bg-light p-2">
                                <h2>Course Content</h2>
                                <hr/>
                                <div className="sidebar_title">Lessons 1 - {course.lessons && course.lessons.length}</div>

                                <div className="sidebar_links">
                                    
                                    <ul>
                                        {(!isLoading) && course.lessons && course.lessons.map((lesson, index) => {
                                            return (
                                                <li><a href={`/course/${course._id}/lesson/${lesson.id}`} onClick={(e) => clickHandler(e, index)}><i className="fas fa-check-circle"></i> Lesson-{lesson.id} {lesson.topic}</a></li>
                                            )
                                        })
                                        }
                                    </ul>
                                </div>


                            </div>

                        </div>
                    </div>
                </div>
            </div> }
	   { (course === null) && <div><br/> Course Does Not Exist <br/></div>}
        </div>
    )
}
