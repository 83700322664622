import React from 'react'
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Activate from '../components/auth/Activate';
import Forgot from '../components/auth/Forgot';
import Login from '../components/auth/Login';
import Register from '../components/auth/Register';
import Page404 from '../components/Page404';

export default function AuthLayout() {
    return (
        <div className="h-100">
                <Switch>
                    <Route path="/auth/login" exact component={Login} />
                    <Route path="/auth/register" exact component={Register} />
                    <Route path="/auth/activate/:id" exact component={Activate} />
                    <Route path="/auth/forgot" exact component={Forgot} />
                    <Route path="*" exact={true} component={Page404}/>

                </Switch>
        </div>
    )
}
