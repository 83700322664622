import React from 'react'

export default function HeroContact() {
    return (
        <div>
            <div className="home1">
                <div className="home_background parallax_background parallax-window" data-parallax="scroll" data-image-src={`${process.env.PUBLIC_URL}/assets/mytheme/images/contact.jpg`} data-speed="0.8"></div>
                <div className="home1_container">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="home_content text-center">
                                    <br/><br/><br/>
                                    <div className="home_title">Contact Us</div>
                                    <div className="breadcrumbs">
                                        {/* <ul>
                                            <li><a href="index.html">Home</a></li>
                                            <li>Punjabi Alphabets</li>
                                        </ul> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
