import React, { useState, useEffect } from 'react'
export default function Practices(props) {
    const [practices, setPractices] = useState([])
    const [scores, setScores] = useState([])
    const [total, setTotal] = useState(0)
    useEffect(() => {
        // console.log(props.data)
        setPractices(props.data.practices)
    }, [])

    const [selectedOption, setSelectedOption] = useState({ qIdx: '', oIdx: '' })
    const changeHandler = (e, quesIndex) => {
        let question = practices[quesIndex]
        //console.log(question)

        if (question.correct == e.target.value) {
            console.log("Correct Answer")
            scores[quesIndex] = { oIdx: e.target.value, score: 1 }
            setScores([...scores])
            console.log(scores)
        } else {
            scores[quesIndex] = { oIdx: e.target.value, score: 0 }
            setScores([...scores])
            console.log(scores)
        }
        setTotal(totalScore)
    }

    const totalScore = () => {
        let sum = 0
        scores.forEach(element => {
            sum += element.score

        })
        let totalCount = practices.length

        return (sum / totalCount)
    }
    return (

        <div className="container">
            <h3>Practice Exercises</h3>
            {practices && (practices.length > 0) && <div>
                {
                    practices.map((practice, index) => {
                        return (
                            <div className="" key={practice._id}>
                                <h6> Question-{index + 1}<div dangerouslySetInnerHTML={{ __html: practice.question }}></div> </h6>
                                <div>

                                    {practice.options.map((option, idx) => {
                                        return (
                                            <div className={(scores && scores[index] && scores[index].score == 1 && scores[index].oIdx == idx) ? "form-check p-2" : "form-check bg-light p-2"} key={idx}>
                                                <input
                                                    className="form-check-input bg-light"
                                                    type="radio"
                                                    name={`practice-${index}`}
                                                    id={`practice-${index}`}
                                                    onChange={e => changeHandler(e, index)}
                                                    value={idx}

                                                />
                                                <label class="form-check-label" for="exampleRadios1">
                                                    {option}
                                                </label>

                                                {(scores && scores[index] && scores[index].score == 1 && scores[index].oIdx == idx) ? <span className="float-right text-success"><i class="fas fa-check-square"></i> Right Answer</span> : ''}
                                                {(scores && scores[index] && scores[index].score == 0 && scores[index].oIdx == idx) ? <span className="float-right text-danger"><i class="fas fa-times-circle"></i> Wrong Answer</span> : ''}
                                            </div>
                                        )
                                    })
                                    }


                                </div>
                            </div>
                        )
                    })
                }
            </div>}
            <br />


            <br />
            <h3>Your Result <span className="text-success">{(total * 100).toFixed(2)}% </span>:</h3>
            {(total == 1) && <h3 style={{color:'#ff6600'}} >Congratulations! You have made good progress.</h3>}
            {/* Total:{total * 100}% */}
            <table>
                <tr>
                    {scores.map(element => {
                        if(element.score == 1){
                            return <td className="p-2"><h1><i className="fa fa-trophy" style={{color:'#ff6600'}} aria-hidden="true"></i></h1></td>
                        }else{
                            //return <i class="fa fa-trophy" aria-hidden="true"></i>
                        }
                        
                    })}
                </tr>
            </table>
           
            

            {/* <button type="button" class="btn btn-primary" onClick={totalScore}>Check Your Score </button> */}
        </div>
    )
}
