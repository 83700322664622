import React,{useState, useEffect} from 'react'
import axios from 'axios'

export default function Footer() {
    const [content, setContent] = useState('')
    
    const loadData = async () => {
        await axios.get(`${process.env.REACT_APP_API_URL}/pages/footer`)
          .then(res => {
  
              //console.log("On load: "+res.data.content)
              setContent(res.data.content)
              //console.log(content)
          })
          .catch(err => console.log(err.message))
      }
  
      useEffect(() => {
         loadData()
        
      },[])
    return (
        <div>
            <footer className="footer">
                <div className="container">
                    <div className="row">


                        <div className="col-lg-3 footer_col">
                            <div className="footer_about">
                                <div className="logo_container">
                                    <a href="#">
                                        <div className="logo_content d-flex flex-row align-items-end justify-content-start">
                                            <div className="logo_img"><img src={`${process.env.PUBLIC_URL}/assets/img/logo12.png`} alt="" /></div>
                                            
                                        </div>
                                    </a>
                                </div>
                                <div className="footer_about_text">
                                    <p>Our mission is to interact with people who want to learn Gurmukhi and Punjabi language.</p>
                                </div>
                                <div className="footer_social">
                                    <ul>
                                        <li><a href="#"><i className="fa fa-google-plus" aria-hidden="true"></i></a></li>
                                        <li><a href="#"><i className="fa fa-pinterest" aria-hidden="true"></i></a></li>
                                        <li><a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                                        <li><a href="#"><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                                    </ul>
                                </div>
                                <div className="copyright">
                                    Copyright &copy;<script>document.write(new Date().getFullYear());</script> All rights reserved 
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 footer_col">
                            <div className="footer_links">
                                <div className="footer_title">Quick menu</div>
                                <ul className="footer_list">
                                    <li><a href="index.html">Home</a></li>
                                    <li><a href="about.html">About us</a></li>
                                    <li><a href="#">Testimonials</a></li>
                                    <li><a href="#">Services</a></li>
                                    <li><a href="contact.html">Contact</a></li>
                                    <li><a href="#">Facts</a></li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-3 footer_col">
                            <div className="footer_links">
                                <div className="footer_title">Useful Links</div>
                                <ul className="footer_list">
                                    <li><a href="courses.html">Courses</a></li>
                                    <li><a href="#">Events</a></li>
                                    <li><a href="news.html">News</a></li>
                                    <li><a href="#">Teachers</a></li>
                                    <li><a href="#">Links</a></li>
                                    <li><a href="#">FAQ</a></li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-3 footer_col">
                            <div className="footer_contact">
                                <div className="footer_title">Contact Us</div>
                                <div dangerouslySetInnerHTML={{__html: content}} />
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

        </div>
    )
}
