import React from 'react'
import StripeContainer from './StripeContainer'
import './Donate.css'
export default function Donate() {
    return (
        <div>
            <br/>
            <div className="row">
                <div className="col-md-6 p-5">
                    <h4>Donate us for the cause</h4>
                    <hr/>
                    <p>Suspendisse tincidunt magna eget massa hendrerit efficitur. Ut euismod pellentesque imperdiet. Cras laoreet gravida lectus, at viverra lorem venenatis in. Aenean id varius quam. Nullam bibendum interdum dui, ac tempor lorem convallis ut. Maecenas rutrum viverra sapien sed fermentum. Morbi tempor odio eget lacus tempus pulvinar.</p>
                </div>
                <div className="col-md-6 p-5">
                    <h4>Donation Form</h4>
                    <hr/>
                    <StripeContainer/>
                </div>

            </div>
        </div>
    )
}
