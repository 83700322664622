import React, {useState, useEffect } from 'react'
import axios from 'axios'
import { getCookie } from '../helpers/Auth'
export function DonationList() {
    const [donations, setDonations ] = useState([])
    const [token, setToken ] = useState(null)
    const [errMessage, setErrMessage ] = useState('')
    const [infoMessage, setInfoMessage ] = useState('')
    const loadData = async() => {   
       await axios.get(`${process.env.REACT_APP_API_URL}/admin/donation`)
        .then(res => {
            console.log(res.data)
            setDonations( res.data.donations)
        })
        .catch(err => {
            //console.log(err)
        })
          

    }
    useEffect(() =>{
        loadData()
       // console.log(donations)
        setToken(getCookie('token'))
       
    },[])

   const deleteHandler = (e, id) => {
        e.preventDefault()
        let x =  window.confirm('Do you really want to Delete this record?')
        if(x){
            axios.delete(`${process.env.REACT_APP_API_URL}/admin/donation/${id}`,
            {
                headers: {
                  
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(res => {
                loadData()
                setInfoMessage(res.data.message)
            })
            .catch( err => {
                console.log(err)
                setErrMessage(err.message)
            })
        }
        //console.log(id)
        
       
        
    }
    return (
        <div className="container">
        <h1>Donation List</h1>
                { (errMessage) && 
                    <div className="bg-danger p-2 text-white">{errMessage}</div>
                }
                { (infoMessage) && 
                    <div className="bg-success p-2 text-white">{infoMessage}</div>
                }
        <table className="table table-stripped">
            
            <thead>
                <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Phone</th>          
                    <th>Email</th>   
                    <th>Amount</th>
                    <th>DateTime</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
            
              
                { (donations.length > 0) && donations.map((donation, index) => {
                    return (
                    <tr key={donation._id}>
                        <td>{index+1}</td>
                        <td>{donation.name}</td>
                        <td>{donation.phone}</td>
                        <td>{donation.email}</td>
                        <td>$ {donation.amount.toFixed(2)} CAD</td>
                        
                        <td>{donation.timestamp}</td>                 
                        <td> <a href="#" id={donation._id} onClick={e => deleteHandler(e, donation._id)}><i className="fas fa-trash-alt text-danger"></i></a></td>
                    </tr>
                    )
                })}
            </tbody>
        </table>
        
    </div>
    )
}

export default DonationList