import React from 'react'
import Footer from '../footers/Footer'
import HeaderMain from '../headers/headerMain/HeaderMain'
import Menu from '../headers/headerMain/Menu'
import HomeHero from '../heros/HomeHero'
import BottomSection from '../pages/home/BottomSection'
import CategorySection from '../pages/home/CategorySection'
import ChooseCourse from '../pages/home/ChooseCourse'
import CourseArea from '../pages/home/CourseArea'
import IconsGrid from '../pages/home/IconsGrid'
import Mission from '../pages/home/Mission'
import Testimonial from '../pages/home/Testimonial'
import Sanjhiwalta from '../pages/home/Sanjhiwalta'


export default function HomePage() {
    return (
        <div  className="super_container">
            <HeaderMain/>
            <Menu/>
            <HomeHero/>
            <br/>
            <Mission/>
            <IconsGrid/>
            {/* <ChooseCourse/> */}
            <Sanjhiwalta/>
            <br/>
            <br/>
            <BottomSection/>
            <Footer/>
            {/* <HomeHero/>
            <CategorySection/>
            <CourseArea/>
            <Testimonial/>
            <Footer/> */}   
        </div>
    )
}
