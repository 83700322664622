import React from 'react'

export default function HeroCatalog() {
    return (
        <div>
            <div className="home1">
                <div className="home1_background parallax_background parallax-window" data-parallax="scroll" data-image-src={`${process.env.PUBLIC_URL}/assets/mytheme/images/about.jpg`} data-speed="0.8"></div>
                <div className="home1_container">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="home_content text-center">
                                    <br/><br/><br/>
                                    <div className="home_title">Course Catalog</div>
                                    <div className="breadcrumbs">
                                      
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
