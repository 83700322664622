import React from 'react'

export default function IconsGrid() {
    return (
        <div>
            <div className="container px-4 py-5" id="icon-grid">
                <div className="section_title text-center"><h2>Special Features</h2></div>
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4 py-5">
                    <div className="col d-flex align-items-start">
                         <span class="milestone_icon bi text-muted flex-shrink-0 me-3"><img width="50" src={`${process.env.PUBLIC_URL}/assets/mytheme/images/milestone_3.svg`} alt=""/></span>
                       
                        <div>
                            <h4 className="fw-bold mb-0">Native Speaker</h4>
                            <p>Audio files recorded by native speakers.</p>
                        </div>
                    </div>
                    <div className="col d-flex align-items-start">
                         <span class="milestone_icon bi text-muted flex-shrink-0 me-3"><img width="50" src={`${process.env.PUBLIC_URL}/assets/mytheme/images/milestone_1.svg`} alt=""/></span>
                       
                        <div>
                            <h4 className="fw-bold mb-0">Emoitonal Connect</h4>
                            <p>Emotional connections with the language.</p>
                        </div>
                    </div>
                    <div className="col d-flex align-items-start">
                         <span class="milestone_icon bi text-muted flex-shrink-0 me-3"><img width="50" src={`${process.env.PUBLIC_URL}/assets/mytheme/images/milestone_2.svg`} alt=""/></span>
                       
                        <div>
                            <h4 className="fw-bold mb-0">Gurbani Essence</h4>
                            <p>One will feel True essence of gurbani.</p>
                        </div>
                    </div>
                    <div className="col d-flex align-items-start">
                         <span class="milestone_icon bi text-muted flex-shrink-0 me-3"><img width="50" src={`${process.env.PUBLIC_URL}/assets/mytheme/images/milestone_4.svg`} alt=""/></span>
                       
                        <div>
                            <h4 className="fw-bold mb-0">Sikh History</h4>
                            <p>Sikh history, tradition and moral values.</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
