import React, {useState} from 'react'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import axios from 'axios'
const CARD_OPTIONS = {
	iconStyle: "solid",
	style: {
		base: {
			iconColor: "#c4f0ff",
			color: "#fff",
			fontWeight: 500,
			fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
			fontSize: "16px",
			fontSmoothing: "antialiased",
			":-webkit-autofill": { color: "#fce883" },
			"::placeholder": { color: "#87bbfd" }
		},
		invalid: {
			iconColor: "#ffc7ee",
			color: "#ffc7ee"
		}
	}
}


export default function PaymentForm() {
    const [success, setSuccess] = useState(false)
    const stripe = useStripe()
    const elements = useElements()

    const handleSubmit = async (e) => {
        e.preventDefault()
        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type:'card',
            card:elements.getElement(CardElement)

        })

        if(!error){
            try {
                const {id} = paymentMethod
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/payment`,{
                    amount:100,
                    id
                })

                if(response.data.success){
                    console.log("Successfull Payment")
                    setSuccess(true)
                }
            } catch (err) {
                console.log("Payment Error:"+err)
            }
        }else{
            console.log(error.message)
        }
    }
    return (
        <div>
            {
                !success ? <form onSubmit={handleSubmit}>
                    <fieldset className="FormGroup">
                        <div className="FormRow">
                            
                        </div>
                        <div className="FormRow">
                            <CardElement options={CARD_OPTIONS}/>
                        </div>
                        <button>Pay</button>
                    </fieldset>
                </form> 
                :
                <div>
                    <h2>Payment Successfull for donation.</h2>
                </div>
            }
        </div>
    )
}
