import React, {useState, useEffect} from 'react'

import LessonCard from './LessonCard'
import axios from 'axios'
import Practices from './Practices'

export function MainContent(props) {
    
    const [course, setCourse] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [courseData, setCourseData] = useState([])
    const [item, setItem] = useState({})
    const [show, setShow] = useState(false)
    const [id, setId] = useState(null)
    const loadData = async () => {
        await axios.get(`${process.env.REACT_APP_API_URL}/courses/${props.course_id}`)
            .then(res => {
               // console.log(res.data.course)
                
                setCourse(res.data.course)
                setItem(res.data.course.lessons[props.id-1])
                setShow(true)
                setIsLoading(false)

            })
            .catch(err => {
                if (err.response)
                    console.log(err.response.data)
            })
    }

    useEffect(() => {
        loadData()

        // setCourseData(lessons)

    }, [])

   
    // const loadData = () => {
    //    setItem(lessons[props.id-1])
    //    setCourseData(lessons)
    //    setShow(true)
    // }
    
    useEffect(() => {

       // console.log(props.id)
       
        loadData()
       

    },[])

    const clickHandler = (e, idx) => {
        
        setId(idx)
        setShow(true)
        //console.log(idx)
      //  setItem(lessons[idx])
       // console.log(item)
    }
    return (
        <div>
            
	        <div className="news">
		        <div className="container">
                    <div className="row">
                            <div className="col-md-7">
                                <div className="news_posts">
                                    <div className="news_post">
                                        {/* <div className="h2">PBL-{course.code}  {course.title}</div>
                                        <hr/> */}
                                        <div><img  className="img-fluid" src={`${process.env.PUBLIC_URL}/uploads/courses/course-${course.code}/lesson-${item.id}/${item.imgSrc}`} alt="Not Displayed"/></div>
                                        <div className="news_post_body"  id="viewCard">
                                            <div className="news_post_date"><a href="#">{item.timestamp}</a></div>
                                            <div className="news_post_title"><a href="#">{item.topic}</a></div>
                                            <div className="news_post_meta d-flex flex-row align-items-start justify-content-start">
                                                <div className="news_post_author">By <a href="#">{course.author}</a></div>
                                                {/* <div className="news_post_comments"><a href="#">3 Comments</a></div>
                                                <div className="news_post_tags">
                                                    <span>in </span>
                                                    <ul>
                                                        <li><a href="#">Social Media</a></li>
                                                    </ul>
                                                </div> */}
                                            </div>
                                            <div className="news_post_text">
                                                <p>{item.description} </p>
                                            </div>
                                            <div className="news_post_link"><a href="#">Read More</a></div>
                                        </div>
                                    </div>

                                    <div className="news_post">
                                        {(!isLoading) && (show) && (item) && <LessonCard data={item} courseCode={course.code} lessonId={item.id}/>}
                                       
                                       
                                        
                                    </div>
                                    <div className="news_post">
                                    {(!isLoading) && (show) && (item) && <Practices data={item} courseCode={course.code} lessonId={item.id}/>}
                                    </div>
                                </div>
                                
                            </div>

                            <div className="col-md-5">
                                    {/* <div className="sidebar_search">
                                        <form action="#" id="sidebar_search_form" className="sidebar_search_form">
                                            <input type="text" className="sidebar_search_input" placeholder="Search" required="required"/>
                                            <button className="sidebar_search_button"><i className="fa fa-search" aria-hidden="true"></i></button>
                                        </form>
                                    </div> */}
                                    <div className="bg-light p-2">
                                        <div className="sidebar_title">PBL-{course.code}  {course.title}</div>
                                        <div className="sidebar_links">
                                            <ul>
                                                
                                                { (!isLoading) && course.lessons && course.lessons.map((lesson,index) => {
                                                        return (
                                                            <li key={index}><a href={`/course/${course._id}/lesson/${lesson.id}`}  ><i className="fas fa-check-circle"></i> Lesson-{index+1} {lesson.topic}</a></li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                        {/* {
                                            courseData.map((lesson,index) => {
                                                return (
                                                    <div>
                                                    <div className="sidebar_links" onClick={(e) => clickHandler(e, index)}><i className="fas fa-check-circle"></i>Dialogue {lesson.topic}</div>
                                                    <div className="sidebar_links">
                                                        
                                                        <ul>
                                                            {
                                                                lesson.conversations.map((conversation,index) => {
                                                                      return <li><i className="fas fa-check-circle" key={conversation.id}></i><a href="#viewCard" onClick={(e) => clickHandler(e, index)}>Dialogue {conversation.id}. {conversation.english}</a></li>
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                    </div>
                                                )
                                            })
                                        } */}
                                        
                                    </div>
                                
                            </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MainContent
