import React from 'react'

export default function Testimonial() {
    return (
        <div>
            <section className="testimonials_area p_120">
                <div className="container">
                    <div className="testi_slider owl-carousel">
                        <div className="item">
                            <div className="testi_item">
                                <img src={`${process.env.PUBLIC_URL}/img/testimonials/testi-3.png`} alt="" />
                                <h4>Fannie Rowe</h4>
                                <ul className="list">
                                    <li><a href="#"><i className="fa fa-star"></i></a></li>
                                    <li><a href="#"><i className="fa fa-star"></i></a></li>
                                    <li><a href="#"><i className="fa fa-star"></i></a></li>
                                    <li><a href="#"><i className="fa fa-star"></i></a></li>
                                    <li><a href="#"><i className="fa fa-star"></i></a></li>
                                </ul>
                                <p>Accessories Here you can find the best computer accessory for your laptop, monitor, printer, scanner, speaker. Here you can find the best computer accessory for your laptop, monitor, printer, scanner, speaker.</p>
                            </div>
                        </div>
                        <div className="item">
                            <div className="testi_item">
                                <img src={`${process.env.PUBLIC_URL}/img/testimonials/testi-3.png`}  alt="" />
                                <h4>Fannie Rowe</h4>
                                <ul className="list">
                                    <li><a href="#"><i className="fa fa-star"></i></a></li>
                                    <li><a href="#"><i className="fa fa-star"></i></a></li>
                                    <li><a href="#"><i className="fa fa-star"></i></a></li>
                                    <li><a href="#"><i className="fa fa-star"></i></a></li>
                                    <li><a href="#"><i className="fa fa-star"></i></a></li>
                                </ul>
                                <p>Accessories Here you can find the best computer accessory for your laptop, monitor, printer, scanner, speaker. Here you can find the best computer accessory for your laptop, monitor, printer, scanner, speaker.</p>
                            </div>
                        </div>
                        <div className="item">
                            <div className="testi_item">
                                <img src={`${process.env.PUBLIC_URL}/img/testimonials/testi-3.png`}  alt="" />
                                <h4>Fannie Rowe</h4>
                                <ul className="list">
                                    <li><a href="#"><i className="fa fa-star"></i></a></li>
                                    <li><a href="#"><i className="fa fa-star"></i></a></li>
                                    <li><a href="#"><i className="fa fa-star"></i></a></li>
                                    <li><a href="#"><i className="fa fa-star"></i></a></li>
                                    <li><a href="#"><i className="fa fa-star"></i></a></li>
                                </ul>
                                <p>Accessories Here you can find the best computer accessory for your laptop, monitor, printer, scanner, speaker. Here you can find the best computer accessory for your laptop, monitor, printer, scanner, speaker.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
