import React from 'react'
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import index from '../components/admin';
import Sidebars from '../components/admin/shared/Sidebars';

import Topbar from '../components/admin/shared/Topbar';
import Footer from '../components/admin/shared/Footer';
import Dashboard from '../components/admin/Dashboard';
import Page404 from '../components/Page404';
import NewCourse from '../components/admin/NewCourse';
import CourseList from '../components/admin/CourseList';
import EditCourse from '../components/admin/EditCourse';
import CreateLesson from '../components/admin/lessons/CreateLesson';
import ViewLessons from '../components/admin/lessons/ViewLessons';
import Dialogue from '../components/admin/lessons/Dialogue';
import EditDialogue from '../components/admin/lessons/EditDialogue';
import EditLesson from '../components/admin/lessons/EditLesson';
import CreateQuestion from '../components/admin/practices/CreateQuestion';
import ViewQuestions from '../components/admin/practices/ViewQuestions';
import IntroSection from '../components/admin/pages/IntroSection';
import MissionSection from '../components/admin/pages/MissionSection';
import SanjhiwaltaSection from '../components/admin/pages/SanjhiwaltaSection';
import JoinSection from '../components/admin/pages/JoinSection';
import FooterEdit from '../components/admin/pages/FooterEdit';
import AboutTegh from '../components/admin/pages/AboutTegh';
import AboutPlatform from '../components/admin/pages/AboutPlatform';
import ContactUs from '../components/admin/pages/ContactUs';
import ContactList from '../components/admin/ContactList';
import DonationList from '../components/admin/DonationList';

// import $ from 'jquery'
// import '../assets/js/sb-admin-2'
// import $ from 'jquery'
// import '../assets/vendor/jquery/jquery.min.js'
// import '../assets/vendor/bootstrap/js/bootstrap.js'
// import '../assets/vendor/bootstrap/js/bootstrap.bundle.min.js'
// import '../assets/vendor/jquery-easing/jquery.easing.min.js'




export default function AdminLayout() {
    return (
        <div id="page-top">
            <div id="wrapper" class="toggled" >
                <div className="bg-dark"><Sidebars/></div>
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <Topbar/>
                        <BrowserRouter>
                            <Switch>
                                
                                <Route path="/admin/dashboard" exact component={Dashboard} />
                                <Route path="/admin/course/create" exact component={NewCourse} />
                                <Route path="/admin/course/create/:id" exact component={EditCourse} />
                                <Route path="/admin/course-list" exact component={CourseList} />
                                <Route path="/admin/lesson/create" exact component={CreateLesson}/>
                                <Route path="/admin/lesson/view" exact component={ViewLessons}/>
                                <Route path='/admin/course/:id/lesson/:idx' exact component={EditLesson}/>
                                <Route path='/admin/course/:id/lesson/:idx/dialogue/add' exact component={Dialogue}/>
                                
                                <Route path='/admin/course/:id/lesson/:idx/dialogue/:dialogueIdx' exact component={EditDialogue}/>
                                <Route path="/admin/course/questions/create" exact component={CreateQuestion} />
                                <Route path="/admin/course/questions-list" exact component={ViewQuestions} />

                                <Route path="/admin/pages/intro" exact component={IntroSection} />    
                                <Route path="/admin/pages/mission" exact component={MissionSection} />    
                                <Route path="/admin/pages/sanjhiwalta" exact component={SanjhiwaltaSection} />    
                                <Route path="/admin/pages/join" exact component={JoinSection} />    
                                <Route path="/admin/pages/footer" exact component={FooterEdit} />       
                                <Route path="/admin/pages/tegh" exact component={AboutTegh} />    
                                <Route path="/admin/pages/platform" exact component={AboutPlatform} />    
                                <Route path="/admin/pages/contact" exact component={ContactUs} />    
                                <Route path="/admin/pages/contact-list" exact component={ContactList} />    
                                <Route path="/admin/pages/donation-list" exact component={DonationList} />    




                                <Route path="/admin" exact component={index} />

                                <Route path="*" exact={true} component={Page404}/>

                            </Switch>
                        </BrowserRouter>
                    </div>
                    <Footer/>
                </div>
            </div>       
             
        </div>
    )
}


