import React from 'react'
import ColumnLeft from './ColumnLeft'
import ColumnRIght from './ColumnRIght'

export default function MainContent() {
    return (

        <div class="contact">
            <div class="container-fluid">
                <div class="row row-xl-eq-height">
                    <div class="col-xl-12">
                        <ColumnLeft/>
                    </div>
                    {/* <div class="col-xl-6">
                        <ColumnRIght/>
                    </div> */}

                </div>
            </div>
        </div>
    )
}
