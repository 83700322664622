import React from 'react'

export default function Sidebars() {
    return (
        <div>
            <ul className="navbar-nav bg-gradient-dark sidebar sidebar-dark accordion" id="accordionSidebar">

                <a className="sidebar-brand d-flex align-items-center justify-content-center" href="/admin">
                    <div className="sidebar-brand-icon rotate-n-10">
                        {/* <i className="fas fa-laugh-wink"></i> */}
                        <img src={`${process.env.PUBLIC_URL}/assets/img/logo12.png`} className="image-fluid" />
                    </div>
                     {/* <div className="sidebar-brand-text mx-3"></div>  */}
                </a>

                <hr className="sidebar-divider my-0" />

                <li className="nav-item active">
                    <a className="nav-link" href="/admin">
                        <i className="fas fa-fw fa-tachometer-alt"></i>
                        <span>Admin Dashboard</span></a>
                </li>

                <hr className="sidebar-divider" />

                <div className="sidebar-heading">
                    Know Punjabi
                </div>

                <li className="nav-item">
                    <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseTwo"
                        aria-expanded="true" aria-controls="collapseTwo">
                        <i className="fas fa-fw fa-cog"></i>
                        <span>Design Courses</span>
                    </a>
                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                        <div className="bg-white py-2 collapse-inner rounded">
                            <h6 className="collapse-header">Manage Courses:</h6>
                            <a className="collapse-item" href="/admin/course/create">Create New Course</a>
                            <a className="collapse-item" href="/admin/course-list">View & Edit Courses</a>
                        </div>
                    </div>
                </li>

                <li className="nav-item">
                    <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseUtilities"
                        aria-expanded="true" aria-controls="collapseUtilities">
                        <i className="fas fa-fw fa-wrench"></i>
                        <span>Lessons & Dialogues</span>
                    </a>
                    <div id="collapseUtilities" className="collapse" aria-labelledby="headingUtilities"
                        data-parent="#accordionSidebar">
                        <div className="bg-white py-2 collapse-inner rounded">
                            <h6 className="collapse-header">Lesson Interface:</h6>
                            <a className="collapse-item" href="/admin/lesson/create">Add Lessons</a>
                            <a className="collapse-item" href="/admin/lesson/view">View Lessons</a>
                            
                        </div>
                    </div>
                </li>
                <li className="nav-item">
                    <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseThree"
                        aria-expanded="true" aria-controls="collapseTwo">
                        <i className="fas fa-fw fa-cog"></i>
                        <span>Practices | Exercise </span>
                    </a>
                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionSidebar">
                        <div className="bg-white py-2 collapse-inner rounded">
                            <h6 className="collapse-header">Manage Practices:</h6>
                            <a className="collapse-item" href="/admin/course/questions/create">Create Questions</a>
                            <a className="collapse-item" href="/admin/course/questions-list">View & Edit Questions</a>
                        </div>
                    </div>
                </li>

                <hr className="sidebar-divider" />

                <div className="sidebar-heading">
                    Page Components
                </div>

                <li className="nav-item">
                    <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsePages"
                        aria-expanded="true" aria-controls="collapsePages">
                        <i className="fas fa-fw fa-folder"></i>
                        <span>Pages</span>
                    </a>
                    <div id="collapsePages" className="collapse" aria-labelledby="headingPages" data-parent="#accordionSidebar">
                        <div className="bg-white py-2 collapse-inner rounded">
                            <h6 className="collapse-header">Home Page:</h6>
                            <a className="collapse-item" href="/admin/pages/intro">Intro Section</a>
                            <a className="collapse-item" href="/admin/pages/mission">Mission Section</a>
                            <a className="collapse-item" href="/admin/pages/sanjhiwalta">Sanjhiwalta Section</a>
                            <a className="collapse-item" href="/admin/pages/join">Join Platform</a>
                            
                           
                        </div>
                        <div className="bg-white py-2 collapse-inner rounded">
                            <h6 className="collapse-header">About Us Page:</h6>
                            <a className="collapse-item" href="/admin/pages/tegh">About Society Section</a>
                            <a className="collapse-item" href="/admin/pages/platform">About Platform</a>
                           
                            
                           
                        </div>
                        <div className="bg-white py-2 collapse-inner rounded">
                            <h6 className="collapse-header">Contact Us Page:</h6>
                            <a className="collapse-item" href="/admin/pages/contact">Contact Content</a>
                           
                            
                           
                        </div>
                        <div className="bg-white py-2 collapse-inner rounded">
                            <h6 className="collapse-header">Footer Page:</h6>
                            <a className="collapse-item" href="/admin/pages/footer">Footer Section</a>
                           
                            
                           
                        </div>
                    </div>
                </li>

                <li className="nav-item">
                    <a className="nav-link" href="/admin/pages/contact-list">
                        <i className="fas fa-fw fa-chart-area"></i>
                        <span>Enquiries</span></a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="/admin/pages/donation-list">
                        <i className="fas fa-fw fa-chart-area"></i>
                        <span>Donations</span></a>
                </li>

                {/*<li className="nav-item">
                    <a className="nav-link" href="tables.html">
                        <i className="fas fa-fw fa-table"></i>
                        <span>Tables</span></a>
                </li> */}

                <hr className="sidebar-divider d-none d-md-block" />

                <div className="text-center d-none d-md-inline">
                    <button className="rounded-circle border-0" id="sidebarToggle"></button>
                </div>

                <div className="sidebar-card d-none d-lg-flex">
                    <img className="sidebar-card-illustration mb-2" src={`${process.env.PUBLIC_URL}`+"/assets/img/undraw_rocket.svg"} alt="..." />
                    <p className="text-center mb-2"><strong>SB Admin Pro</strong> is packed with premium features, components, and more!</p>
                    <a className="btn btn-success btn-sm" href="https://startbootstrap.com/theme/sb-admin-pro">Upgrade to Pro!</a>
                </div>

            </ul>
        </div>
    )
}
