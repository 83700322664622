import React from 'react'

export default function CourseArea() {
    return (
        <div>
            <section className="courses_area p_120">
                <div className="container">
                    <div className="main_title">
                        <h2>Popular Free Courses</h2>
                        <p>There is a moment in the life of any aspiring astronomer that it is time to buy that first telescope. It’s exciting to think about setting up your own viewing station.</p>
                    </div>
                    <div className="row courses_inner">
                        <div className="col-lg-9">
                            <div className="grid_inner">
                                <div className="grid_item wd55">
                                    <div className="courses_item">
                                        <img src={`${process.env.PUBLIC_URL}/assets/learnit/img/courses/course-1.jpg`} alt="" />
                                        <div className="hover_text">
                                            <a className="cat" href="#">Free</a>
                                            <a href="#"><h4>Japanease Language className</h4></a>
                                            <ul className="list">
                                                <li><a href="#"><i className="lnr lnr-users"></i> 355</a></li>
                                                <li><a href="#"><i className="lnr lnr-bubble"></i> 35</a></li>
                                                <li><a href="#"><i className="lnr lnr-user"></i> T. Robert</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid_item wd44">
                                    <div className="courses_item">
                                        <img src={`${process.env.PUBLIC_URL}/assets/learnit/img/courses/course-2.jpg`} alt="" />
                                        <div className="hover_text">
                                            <a className="cat" href="#">Free</a>
                                            <a href="#"><h4>Japanease Language className</h4></a>
                                            <ul className="list">
                                                <li><a href="#"><i className="lnr lnr-users"></i> 355</a></li>
                                                <li><a href="#"><i className="lnr lnr-bubble"></i> 35</a></li>
                                                <li><a href="#"><i className="lnr lnr-user"></i> T. Robert</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid_item wd44">
                                    <div className="courses_item">
                                        <img src={`${process.env.PUBLIC_URL}/assets/learnit/img/courses/course-4.jpg`} alt="" />
                                        <div className="hover_text">
                                            <a className="cat" href="#">Free</a>
                                            <a href="#"><h4>Japanease Language className</h4></a>
                                            <ul className="list">
                                                <li><a href="#"><i className="lnr lnr-users"></i> 355</a></li>
                                                <li><a href="#"><i className="lnr lnr-bubble"></i> 35</a></li>
                                                <li><a href="#"><i className="lnr lnr-user"></i> T. Robert</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid_item wd55">
                                    <div className="courses_item">
                                        <img src={`${process.env.PUBLIC_URL}/assets/learnit/img/courses/course-5.jpg`} alt="" />
                                        <div className="hover_text">
                                            <a className="cat" href="#">Free</a>
                                            <a href="#"><h4>Japanease Language className</h4></a>
                                            <ul className="list">
                                                <li><a href="#"><i className="lnr lnr-users"></i> 355</a></li>
                                                <li><a href="#"><i className="lnr lnr-bubble"></i> 35</a></li>
                                                <li><a href="#"><i className="lnr lnr-user"></i> T. Robert</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="course_item">
                                <img src={`${process.env.PUBLIC_URL}/assets/learnit/img/courses/course-3.jpg`} alt="" />
                                <div className="hover_text">
                                    <a className="cat" href="#">Free</a>
                                    <a href="#"><h4>Japanease Language className</h4></a>
                                    <ul className="list">
                                        <li><a href="#"><i className="lnr lnr-users"></i> 355</a></li>
                                        <li><a href="#"><i className="lnr lnr-bubble"></i> 35</a></li>
                                        <li><a href="#"><i className="lnr lnr-user"></i> T. Robert</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
