import React, {useState, useEffect} from 'react'
import axios from 'axios'
import {getCookie} from '../../helpers/Auth'

export default function Dialogue(props) {
    const [dialogue, setDialogue] = useState({
        
        english:'',
        punjabi:'',
        pbInEng:'',
        audio:'',
        isFlipped:false
    })
    // const [courseId, setCourseId] = useState(null)
    // const [lessonIdx, setLessonIdx] = useState(null)
    const [selectedCourse, setSelectedCourse] = useState(null)
    const [info, setInfo] = useState(null)
    const [error, setError] = useState(null)
    const config2 = {
        headers: {
            
            'Authorization': 'Bearer ' + getCookie('token')
        }
    }
    const loadData = async () => {    
        await axios.get(`${process.env.REACT_APP_API_URL}/courses/view/${props.match.params.id}`,config2)
        .then(res => {
            setSelectedCourse(res.data.course)
           // console.log(res.data)
        })
        .catch(err => {
            if(err.response){
                console.log(err.response.data)
            }
           // console.log(err.response.data)
        })
    }
    useEffect(() => {
        
        loadData()
        // console.log(props.match.params.id)
        // console.log(props.match.params.idx)
    },[dialogue])
    const changeHandler = (e) => {
        dialogue[e.target.name] = e.target.value
        if (e.target.name == 'audio') {

            dialogue[e.target.name] = e.target.files[0]
        }
        setDialogue({...dialogue})

    }
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + getCookie('token')
        }
    }
    const submitHandler = async (e) => {
        e.preventDefault()
        //console.log(dialogue)
        const formData = new FormData()
        formData.append("courseCode",selectedCourse.code)
        formData.append("lesson_no",selectedCourse.lessons[props.match.params.idx].id)
        formData.append("english", dialogue.english)
        formData.append("punjabi",dialogue.punjabi)
        formData.append("pbInEng",dialogue.pbInEng)
        formData.append("audio",dialogue.audio)

       await axios.put(`${process.env.REACT_APP_API_URL}/courses/${selectedCourse._id}/lesson/${props.match.params.idx}/dialogue/create`,
            formData,
            config
        )
        .then(res => {
           // console.log(res.data)
            
            setDialogue({
        
                english:'',
                punjabi:'',
                pbInEng:'',
                audio:'',
                isFlipped:false
            })
            //setSelectedCourse(null)
            //sloadData()
            setInfo(res.data)
            setError('')
        })
        .catch(err => {
            setInfo('')
            
            if(err.response){
                console.log(err.response.data)
                setError(err.response.data)
            }
        })  
       
    }

    const handleDelete = (e, index) => {
        axios.delete(`${process.env.REACT_APP_API_URL}/courses/${selectedCourse._id}/lessons/${props.match.params.idx}/dialogue/${index}`,config2)
        .then(res => {
            setInfo(res.data.message)
            setError('')
            setSelectedCourse(res.data.course)
        })
        .catch(err => {
            setInfo('')
            
            if(err.response){
                setError(err.response.data)
                console.log(err.response.data)
            }
               
        })

    }

    const dropHandler = (e, idx) => {

        console.log(idx + "called")
    }
    const { id, english, punjabi, pbInEng, audio } = dialogue
    return (
        <div className="container-fluid p-3">
            <h3 className="text-primary">Course Name: {(selectedCourse) && selectedCourse.title} </h3><br/> <h5 className="text-danger">Lesson-{(selectedCourse) && selectedCourse.lessons[props.match.params.idx].id} : {(selectedCourse) && selectedCourse.lessons[props.match.params.idx].topic} </h5>
            <h5> Enter Dialogue-{ selectedCourse && selectedCourse.lessons[props.match.params.idx].dialogues.length + 1}</h5>
            <hr/>
            { (info) && <div className="bg-success p-2 text-white">{info}<br/></div>}
               { (error) && <div className="bg-danger p-2 text-white">{error}<br/></div>}
               <br/>

           {selectedCourse && <form onSubmit={e => submitHandler(e)}>
                <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Dialogue In English</label>
                    <input
                        type="text"
                        name="english"
                        className="form-control"
                        id="english"
                        aria-describedby="emailHelp"
                        placeholder="Enter Name Of the Course"
                        onChange={e => changeHandler(e)}
                        value={english}
                        required

                    />
                    <small id="emailHelp" className="form-text text-muted">Dialogue in English for converstaion</small>
                </div>
                <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Dialogue In Punjabi</label>
                    <input
                        type="text"
                        name="punjabi"
                        className="form-control"
                        id="punjabi"
                        aria-describedby="emailHelp"
                        placeholder="Enter Name Of the Course"
                        onChange={e => changeHandler(e)}
                        value={punjabi}
                        required

                    />
                    <small id="emailHelp" className="form-text text-muted">Dialogue in Punjabi for converstaion</small>
                </div>
                <div className="form-group">
                    <label htmlFor="exampleInputEmail1">English version of Punjabi Text.</label>
                    <input
                        type="text"
                        name="pbInEng"
                        className="form-control"
                        id="pbInEng"
                        aria-describedby="emailHelp"
                        placeholder="Enter Name Of the Course"
                        onChange={e => changeHandler(e)}
                        value={pbInEng}
                        required

                    />
                    <small id="emailHelp" className="form-text text-muted">Dialogue in Punjabi for converstaion</small>
                </div>
                <div className="form-group">
                        <label htmlFor="exampleFormControlFile1">Upload Audio File for the Dialog in mp3 format only.</label>
                        <input 
                        type="file" 
                        className="form-control-file" 
                        name="audio" 
                        id="audio"
                       	
                        onChange = {e => changeHandler(e)}
                        required
                        />
                </div>
                <div className="form-group">
                    <button type="submit" className="btn btn-primary">Save the dialogue</button>
                </div>

            </form>}
            <hr/>
            <div className="h3 text-center">List of Dialogues For The Selected Course</div>

            <table class="table">
                <thead class="thead-dark">
                    <tr>
                    <th scope="col">#</th>
                    <th scope="col">English</th>
                    <th scope="col">Punjabi</th>
                    <th scope="col">Punjabi In English</th>
                    <th scope="col">AudioFile</th>
                    <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                {selectedCourse && selectedCourse.lessons[props.match.params.idx].dialogues.map((dialogue, index) => {

                        return <tr draggable="true" onDrop={e => dropHandler(e, index)} key={index} className="droppable">
                                <td>Dialogue-{index+1}</td>
                                <td>{dialogue.english}</td>
                                <td>{dialogue.punjabi}</td>
                                <td>{dialogue.pbInEng}</td>
                                <td><audio controls autoplay>
                                    <source src={`${process.env.PUBLIC_URL}/uploads/courses/course-${selectedCourse.code}/lesson-${parseInt(props.match.params.idx)+1}/${dialogue.audio}`} type={`${dialogue.mimeType}`}/>
                                    Your browser does not support the audio element.
                                </audio></td>
                                <td><a href={`/admin/course/${selectedCourse._id}/lesson/${props.match.params.idx}/dialogue/${index}`} className="btn text-primary btn-link">Edit</a> | <button title="Delete Dialogue" onClick={e => handleDelete(e,index)} className="btn text-danger btn-link">Delete</button></td>
                            
                            </tr>

                })}
                </tbody>
            </table>
           
        </div>
    )
}
