import React,{useState, useEffect} from 'react'
import axios from 'axios'

export default function ColumnLeft() {
	const [contactData, setContactData ] = useState({
        name: '',
        mobileno:'',
        email:'',
        subject:'',
        message:''
    })
    const [data, setData] = useState(null)
    const changeHandler = (e) => {
        
        contactData[e.target.name] = e.target.value
        setContactData({...contactData})
       // console.log(contactData)


    }

    const[ resMsg, setResMsg ] = useState(null)
	const [content, setContent] = useState('')
    
    const loadData = async () => {
        await axios.get(`${process.env.REACT_APP_API_URL}/pages/contact`)
          .then(res => {
  
              //console.log("On load: "+res.data.content)
              setContent(res.data.content)
              //console.log(content)
          })
          .catch(err => console.log(err.message))
      }
  
      useEffect(() => {
         loadData()
        
      },[])

	  const onSubmitHandler = (e) => {

        e.preventDefault()

        axios.post(`${process.env.REACT_APP_API_URL}/user/contactus`,{
            name,
            mobileno,
            email,
            subject,
            message

        })
        .then( res => {
            setResMsg(res.data.message)
            setTimeout(() => {
                setResMsg('')
            },5000)
            setContactData({
                name: '',
                mobileno: '',
                email:'',
                subject:'',
                message:''
            })

        })
        .catch( err => {

            console.log(err)
        })
        //console.log(contactData)

    }


	  const { name, mobileno, email, subject, message } = contactData
    return (
        <div>
            <div className="contact_content">
						<div dangerouslySetInnerHTML={{__html: content}} />
						
						<div className="contact_form_container">
							<h3>Send Us A Message </h3>
							{(resMsg) && 
                                <div className="p-2 round contact-form-head text-success">
                                
                                <p>{resMsg}....</p>
                            </div>
                            }
							<form  onSubmit={e => onSubmitHandler(e)}  className="contact_form">
								<div>
									<div className="row">
										<div className="col-lg-6 contact_name_col">
											<input type="text"
											 className="contact_input"
											 placeholder="Name"
											 name="name"
											 id="name"
											 onChange = { e => changeHandler(e)}
                                        	 value = {name}
                                        	 required
											 />
										</div>
										<div className="col-lg-6">
											<input type="mobile"
											 className="contact_input"
											 placeholder="Mobile No"
											 name="mobileno"
											 id="mobileno"
											 onChange = { e => changeHandler(e)}
                                        	 value = {mobileno}
											 required="required"/>
										</div>
									</div>
								</div>
								<div>
										<input type="email"
											 className="contact_input"
											 placeholder="E-mail"
											 name="email"
											 id="email"
											 onChange = { e => changeHandler(e)}
                                        	 value = {email}
											 required="required"/>
										
								</div>
								<div><input 
									 type="text"
									 className="contact_input" 
									 placeholder="Subject" 
									 required="required"
									 name="subject"
									 id="subject"
									 onChange = { e => changeHandler(e)}
                                     value = {subject}
									 
									 /></div>
								<div><textarea 
									className="contact_input contact_textarea"
									placeholder="Message"
									id="message"
                                    name="message"
                                    onChange = { e => changeHandler(e)}
								    >
										{message}
									</textarea></div>
								<button className="contact_button"><span>send message</span><span className="button_arrow"><i className="fa fa-angle-right" aria-hidden="true"></i></span></button>
							</form>
						</div>
					</div>
        </div>
    )
}
