import React,{useState, useEffect} from 'react'
import axios from 'axios'
export default function HomeHero() {

    const [content, setContent] = useState('')
    
    const loadData = async () => {
        await axios.get(`${process.env.REACT_APP_API_URL}/pages/intro`)
          .then(res => {
  
              //console.log("On load: "+res.data.content)
              setContent(res.data.content)
              console.log(content)
          })
          .catch(err => console.log(err.message))
      }
  
      useEffect(() => {
         loadData()
        
      },[])
    return (
        <div>
            <div className="home">
                <div className="home_slider_container">

                    <div className="owl-carousel owl-theme home_slider">

                        <div className="owl-item">
                            <div className="home_slider_background bg-background-img1"></div>
                            <div className="home_container">
                                <div className="container">
                                    <div className="row">
                                        <div className="col">
                                            <div className="home_content text-center">
                                                <div className="home_logo"><img src="images/home_logo.png" alt="" /></div>
                                                <div className="home_text">
                                                    <div className="home_title">Know Punjabi Step By Step</div>
                                                    <div className="home_subtitle">Maecenas rutrum viverra sapien sed fermentum. Morbi tempor odio eget lacus tempus pulvinar. Praesent vel nisl fermentum, gravida augue ut, fermentum ipsum.</div>
                                                </div>
                                                <div className="home_buttons">
                                                    <div className="button home_button"><a href="#">Know Gurbani<div className="button_arrow"><i className="fa fa-angle-right" aria-hidden="true"></i></div></a></div>
                                                    <div className="button home_button"><a href="#">Know Punjabi<div className="button_arrow"><i className="fa fa-angle-right" aria-hidden="true"></i></div></a></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className="owl-item">
                            <div className="home_slider_background  bg-background-img1"></div>
                            <div className="home_container">
                                <div className="container">
                                    <div className="row">
                                        <div className="col">
                                            <div className="home_content text-center">
                                                <div className="home_logo"><img src="images/home_logo.png" alt="" /></div>
                                                <div className="home_text">
                                                    <div className="home_title">Complete Online Courses</div>
                                                    <div className="home_subtitle">Maecenas rutrum viverra sapien sed fermentum. Morbi tempor odio eget lacus tempus pulvinar. Praesent vel nisl fermentum, gravida augue ut, fermentum ipsum.</div>
                                                </div>
                                                <div className="home_buttons">
                                                    <div className="button home_button"><a href="#">learn more<div className="button_arrow"><i className="fa fa-angle-right" aria-hidden="true"></i></div></a></div>
                                                    <div className="button home_button"><a href="#">see all courses<div className="button_arrow"><i className="fa fa-angle-right" aria-hidden="true"></i></div></a></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="owl-item">
                            <div className="home_slider_background  bg-background-img1"></div>
                            <div className="home_container">
                                <div className="container">
                                    <div className="row">
                                        <div className="col">
                                            <div className="home_content text-center">
                                                <div className="home_logo"><img src="images/home_logo.png" alt="" /></div>
                                                <div className="home_text">
                                                    <div className="home_title">Online Punjabi Course</div>
                                                    <div className="home_subtitle">Maecenas rutrum viverra sapien sed fermentum. Morbi tempor odio eget lacus tempus pulvinar. Praesent vel nisl fermentum, gravida augue ut, fermentum ipsum.</div>
                                                </div>
                                                <div className="home_buttons">
                                                    <div className="button home_button"><a href="#">learn more<div className="button_arrow"><i className="fa fa-angle-right" aria-hidden="true"></i></div></a></div>
                                                    <div className="button home_button"><a href="#">see all courses<div className="button_arrow"><i className="fa fa-angle-right" aria-hidden="true"></i></div></a></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                    </div>
                </div>
            </div>
            <div className="featured">
		<div className="container">
			<div className="row">
				<div className="col">
{/* 					
					<div className="home_slider_nav_container d-flex flex-row align-items-start justify-content-between">
						<div className="home_slider_nav home_slider_prev trans_200"><i className="fa fa-angle-left" aria-hidden="true"></i></div>
						<div className="home_slider_nav home_slider_next trans_200"><i className="fa fa-angle-right" aria-hidden="true"></i></div>
					</div> */}
					<div className="featured_container">
						<div className="row">
							<div className="col-lg-6 featured_col">
								<div className="featured_content">
									<div className="featured_header d-flex flex-row align-items-center justify-content-start">
										{/* <div className="featured_tag"><a href="#">Featured</a></div> */}
										{/* <div className="featured_price ml-auto">Price: <span>Free</span></div> */}
									</div>
                                    <div dangerouslySetInnerHTML={{__html: content}} />
								</div>
							</div>
							<div className="col-lg-6 featured_col">
							
								<div className="featured_background  bg-featured-img"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
        </div>
    )
}
