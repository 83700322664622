import React, { useState, useEffect } from 'react'
import axios from 'axios'

export default function Catalog() {
    const [courses, setCourses] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    const loadData = async () => {
        await axios.get(`${process.env.REACT_APP_API_URL}/courses/published`)
            .then(res => {
                //console.log(res.data.courses)
                setIsLoading(false)
                setCourses(res.data.courses)
            })
            .catch(err => {
                if (err.response)
                    console.log(err.response.data)
            })
    }
    //Testing 
    useEffect(() => {

        loadData()
        //console.log(tempCourses)

    }, [])


    return (
        <div className="about1">
            <div className="container">
                <div className="row courses_row">
                {!isLoading && courses.map((course, index) => {
                    return (
                        <div className="col-lg-4 col-md-6" key={index}>
                            <div className="course">
                                <div className="course_image"><img src={`${process.env.PUBLIC_URL}/uploads/courses/course-${course.code}/${course.imgSrc}`} alt="" /></div>
                                <div className="course_body">
                                    <div className="course_header d-flex flex-row align-items-center justify-content-start">
                                        <div className="course_tag text-center"><a href={`course/${course._id}`}>Launch Course</a></div>
                                        {/* <div className="course_price ml-auto">Price: <span>Free</span></div> */}
                                    </div>
                                    <div className="course_title"><h3><a href={`course/${course._id}`}>{course.title}</a></h3></div>
                                    <div className="course_text"><div dangerouslySetInnerHTML={{__html: course.shortDesc}}></div></div>
                                    <div className="course_footer d-flex align-items-center justify-content-start">
                                        {/* <div className="course_author_image"><img src="images/featured_author.jpg" alt="https://unsplash.com/@anthonytran" /></div> */}
                                        <div className="course_author_name">By <a href="#">{course.author}</a></div>
                                        {/* <div className="course_sales ml-auto"><span>352</span> Sales</div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
                </div>
            </div>
        </div>
    )
}
