import React, {useState, useEffect, useRef} from 'react'
import {getCookie} from '../../helpers/Auth'
import JoditEditor from "jodit-react";

import axios from 'axios'
export default function FooterEdit() {

    const editor = useRef(null)
	const [content, setContent] = useState('')
    const [token , setToken ] = useState(null)
    const [info, setInfo] = useState(null)
    const [error, setError] = useState(null)
    const loadData = async () => {
        await axios.get(`${process.env.REACT_APP_API_URL}/pages/footer`)
          .then(res => {
  
              //console.log("On load: "+res.data.content)
              setContent(res.data.content)
          })
          .catch(err => console.log(err.message))
      }
  
      useEffect(() => {
         loadData()
         let tk = getCookie('token')
  
         if(tk){
             setToken(tk)
         }
      },[])
    const config3 = {
		readonly: false // all options from https://xdsoft.net/jodit/doc/
    }
    const config2 = {
        headers: {
            
            'Authorization': 'Bearer ' + getCookie('token')
        }
    }
    
    const submitHandler = (e) => {
        e.preventDefault()
        console.log("Called")
        console.log(content)
        axios.post(`${process.env.REACT_APP_API_URL}/pages/footer`,{content},config2)
        .then(res => {
           console.log(res.data)
           setInfo(res.data.message)
        })
        .catch(err => {
            console.log(err.response.data)
        })
       // console.log(content)
    }
    return (
        <div>
             <div className="container">
               <h3>Update Footer Section Of All Pages</h3>
               <hr/>
                { (info) && <div className="bg-success p-2 text-white">{info}</div>}
                { (error) && <div className="bg-danger p-2 text-white">{error}</div>}
                <form onSubmit={e => submitHandler(e)}>
                    <div className="form-group">
                        <label htmlFor="exampleInputPassword1"></label>
                        
                        <JoditEditor
                            ref={editor}
                            value={content}
                            config={config3}
                            id="description"
                            name="description"
                            tabIndex={1} // tabIndex of textarea
                            onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                            //onChange = {e => changeHandler(e)}
                        />
                    </div>
                    <div className="form-group">
                        <button type="submit" className="btn btn-primary">Click To Update the Footer Section Content</button>
                    </div>
                    
                </form>
                <hr/>
                    <h1 className="text-center">Preview Content</h1>
                    <br/>
                    <div className="container">
                        <div dangerouslySetInnerHTML={{__html: content}} />
                    </div>
                    
             </div>
        </div>
    )
}
