import React from 'react'
import Footer from '../footers/Footer'
import HeaderMain from '../headers/headerMain/HeaderMain'
import Menu from '../headers/headerMain/Menu'
import HeroContact from '../heros/HeroContact'



import MainContent from '../pages/contactus/MainContent'

export default function ContactView() {
    return (
        <div  className="super_container">
            <HeaderMain/>
            <Menu/>
            <HeroContact/>
            <MainContent/>
            
            
            <br/>
            <br/>
            {/* <img src={`${process.env.PUBLIC_URL}/assets/mytheme/images/about.jpg`} />
            <div className="home_container">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="home_content text-center">
                                    <div className="home_title">Punjabi Lessons</div>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br/>
                <br/> */}
            <Footer/>

            {/* <HomeHero/>
            <CategorySection/>
            <CourseArea/>
            <Testimonial/>
            <Footer/> */}   
        </div>
    )
}
